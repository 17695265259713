import React from 'react';

import Container from '../helpers/Container';

export const WithCenteredContainerWrapper = ({ backButtonTitle, backUrl, children }) => {
    return (
        <Container backButtonTitle={backButtonTitle} backUrl={backUrl}>
            <div className={'flex-column align-items-center justify-content-center'}>{children}</div>
        </Container>
    );
};
