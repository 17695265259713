import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { INSEE_ORGANIZATION_THROUGH_SIREN_KEY } from '../../config/constants';
import organizationFinder from '../../store/services/organization-finder';
import storage from '../../utils/storage';

// use it for testing
/*const mockedData = {
    data: {
        name: 'RENOLIB',
        siren: '848905949',
        siret: '84890594900048',
        mainEtablissement: {
            siren: 848905949,
            siret: 84890594900014,
            name: 'RENOLIB',
            creation_date: '2019-03-01',
            capital: '50000',
            commercialName: 'RENOLIB',
            ape: 'SAS, société par actions simplifiée',
            naf: '70.22Z',
            vat: 'FR28848905949',
            contacts: [
                {
                    lastName: 'THR MANAGEMENT',
                    role: 'Président',
                    isMainContact: true,
                },
                {
                    lastName: 'FONDARY CONSEILS',
                    role: 'Directeur général',
                    name: 'FONDARY CONSEILS',
                    isCompany: true,
                    isMainContact: false,
                },
            ],
        },
        etablissements: [
            {
                uniteLegale: {
                    etatAdministratifUniteLegale: 'A',
                    statutDiffusionUniteLegale: 'O',
                    dateCreationUniteLegale: '2019-03-01',
                    categorieJuridiqueUniteLegale: '5710',
                    denominationUniteLegale: 'RENOLIB',
                    sigleUniteLegale: null,
                    denominationUsuelle1UniteLegale: null,
                    denominationUsuelle2UniteLegale: null,
                    denominationUsuelle3UniteLegale: null,
                    sexeUniteLegale: null,
                    nomUniteLegale: null,
                    nomUsageUniteLegale: null,
                    prenom1UniteLegale: null,
                    prenom2UniteLegale: null,
                    prenom3UniteLegale: null,
                    prenom4UniteLegale: null,
                    prenomUsuelUniteLegale: null,
                    pseudonymeUniteLegale: null,
                    activitePrincipaleUniteLegale: '70.22Z',
                    nomenclatureActivitePrincipaleUniteLegale: 'NAFRev2',
                    identifiantAssociationUniteLegale: null,
                    economieSocialeSolidaireUniteLegale: 'N',
                    societeMissionUniteLegale: null,
                    caractereEmployeurUniteLegale: null,
                    trancheEffectifsUniteLegale: '11',
                    anneeEffectifsUniteLegale: '2022',
                    nicSiegeUniteLegale: '00014',
                    dateDernierTraitementUniteLegale: '2024-03-22T09:28:19.000',
                    categorieEntreprise: 'PME',
                    anneeCategorieEntreprise: '2022',
                },
                siren: '848905949',
                siret: '84890594900048',
                addressName: null,
                postalCode: '75014',
                isClosed: false,
                latitude: 48.841681999801864,
                longitude: 2.3284290000027803,
                etablissementSiege: false,
                etatAdministratifEtablissement: 'A',
                denominationUsuelleEtablissement: null,
                city: 'Paris',
                country: 'France',
                complementAdresseEtablissement: null,
                containStreetNumber: true,
                buildingSector: 'TERTIARY',
                streetNumber: '9',
                streetName: 'Rue Delambre',
            },
            {
                uniteLegale: {
                    etatAdministratifUniteLegale: 'A',
                    statutDiffusionUniteLegale: 'O',
                    dateCreationUniteLegale: '2019-03-01',
                    categorieJuridiqueUniteLegale: '5710',
                    denominationUniteLegale: 'RENOLIB',
                    sigleUniteLegale: null,
                    denominationUsuelle1UniteLegale: null,
                    denominationUsuelle2UniteLegale: null,
                    denominationUsuelle3UniteLegale: null,
                    sexeUniteLegale: null,
                    nomUniteLegale: null,
                    nomUsageUniteLegale: null,
                    prenom1UniteLegale: null,
                    prenom2UniteLegale: null,
                    prenom3UniteLegale: null,
                    prenom4UniteLegale: null,
                    prenomUsuelUniteLegale: null,
                    pseudonymeUniteLegale: null,
                    activitePrincipaleUniteLegale: '70.22Z',
                    nomenclatureActivitePrincipaleUniteLegale: 'NAFRev2',
                    identifiantAssociationUniteLegale: null,
                    economieSocialeSolidaireUniteLegale: 'N',
                    societeMissionUniteLegale: null,
                    caractereEmployeurUniteLegale: null,
                    trancheEffectifsUniteLegale: '11',
                    anneeEffectifsUniteLegale: '2022',
                    nicSiegeUniteLegale: '00014',
                    dateDernierTraitementUniteLegale: '2024-03-22T09:28:19.000',
                    categorieEntreprise: 'PME',
                    anneeCategorieEntreprise: '2022',
                },
                siren: '848905949',
                siret: '84890594900014',
                addressName: null,
                postalCode: '91300',
                isClosed: false,
                latitude: 48.7292059998019,
                longitude: 2.2676440000030254,
                buildingSector: 'TERTIARY',
                etablissementSiege: true,
                etatAdministratifEtablissement: 'A',
                denominationUsuelleEtablissement: 'RENOLIB',
                city: 'Massy',
                country: 'France',
                complementAdresseEtablissement: null,
                containStreetNumber: true,
                streetNumber: '5',
                streetName: 'Avenue Carnot',
            },
        ],
    },
};
/**
 * @param {SearchParams} searchParams
 */
export default function useGetOrganizationInseeQueryThroughSiren(siren, options = { apiMode: 'NORMAL' }) {
    // used it for testing.
    /* const { data, isLoading, error, isError } = useQuery({
        queryKey: [INSEE_ORGANIZATION_THROUGH_SIREN_KEY, siren],
        queryFn: () => new Promise((resolve) => resolve(mockedData)), //organizationFinder.getOrganizationInseeInformationThroughSiren({ siren }),
        enabled: true, //storage.isStorageAvailable() && !!siren && siren.length === 9, //put it to true if you are using it with mocked data
        staleTime: 10 * 60 * 1000,
    }); */
    const { data, isLoading, error, isError, isSuccess } = useQuery({
        queryKey: [INSEE_ORGANIZATION_THROUGH_SIREN_KEY, siren, options.apiMode],
        queryFn: () => organizationFinder.getOrganizationInseeInformationThroughSiren({ siren, options }),
        enabled: storage.isStorageAvailable() && !!siren && siren.length === 9, //put it to true if you are using it with mocked data
        staleTime: 10 * 60 * 1000,
        retry: false,
    });

    const [isAnErrorTriggeringManualMode, setIsAnErrorTriggeringManualMode] = useState(false);

    const errorMsg = useMemo(() => {
        if (!isError) return;

        if (error?.response?.status >= 400 && error?.response?.status <= 600) {
            if (error?.response?.status >= 500) {
                return `Une erreur interne est survenue. Cependant vous pouvez cliquer sur "Suivant" et entrer les informations manuellements`;
            }
            return error?.response?.data?.message;
        }
    }, [isError, error]);

    useEffect(() => {
        if (!isError) return;

        if (error?.response?.status >= 400 && error?.response?.status <= 600) {
            if (error?.response?.status >= 500) {
                setIsAnErrorTriggeringManualMode(true);
            } else {
                setIsAnErrorTriggeringManualMode(false);
            }
        }
    }, [isError, error]);

    return {
        inseeCustomer: !isError && data?.data ? [{ label: `${data.data.name} - ${data.data.siren}`, value: data.data }] : [],
        totalCount: data?.totalCount ?? 0,
        isLoading,
        isSuccess,
        isError,
        isAnErrorTriggeringManualMode,
        error: errorMsg,
    };
}
