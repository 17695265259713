export default [
    {
        title: 'Bureaux',
        sectors: [
            'les locaux où sont exercées des activités bancaires, financières ou d’assurance,',
            'les locaux où sont exercées des activités de vente, de location, de gestion de biens immobiliers,',
            'les bureaux de postes, les centres d’appels,',
            'les agences de voyages,',
            'les postes de police et de gendarmerie,',
            'les cabinets médicaux, paramédicaux, dentaires ou vétérinaires, les laboratoires d’analyse médicales ou d’imageries médicales en dehors des établissements hospitaliers,',
            'les salles de quartiers.',
        ],
    },
    {
        title: 'Enseignement :',
        sectors: [
            'les locaux où sont exercées des activités de formation pour adultes,',
            'les locaux où sont exercées des activités de recherche et de développement,',
            'les locaux destinés à l’enseignement des soins infirmiers inclus ou non dans un établissement hospitalier.',
        ],
    },
    {
        title: 'Commerces :',
        sectors: [
            'les locaux destinés à la vente ou à la location de biens (exemple : véhicules, articles de sport ou de loisir, matériel informatique, etc.) et de services,’, ’les réserves associées aux commerces,',
            'les entrepôts (frigorifiques ou non) et les locaux de stockage,',
            'les blanchisseries non industrielles (les blanchisseries industrielles relèvent du secteur industriel),',
            'les pharmacies,',
            'les ateliers de réparation et d’entretien de véhicules.',
        ],
    },
    {
        title: 'Hôtellerie Restauration :',
        sectors: [
            'les locaux où sont exercé le métier de traiteur,',
            'les locaux destinés à l’organisation de réception,',
            'les cafés et les autres débuts de boissons,',
            'les lieux de vente de tabac associée à un débit de boissons et destinés à la consommation sur place,',
            'les locaux destinés à l’hébergement touristique suivants : auberge de jeunesse, refuge, centre de vacances, résidence de tourisme, terrains de camping, de caravaning ou comportant des chalets, bungalows ou mobil homes,',
            'les cuisines centrales pour la fabrication de préparations culinaires destinées à être livrées.',
        ],
    },
    {
        title: 'Santé :',
        sectors: [
            'les locaux où sont exercé des activités thermales et de thalassothérapie,',
            'les locaux destinés à accueillir des enfants ou des adultes handicapés comme les SAVS (services d’accompagnement à la vie sociale),',
            'les centres de collecte et les banques d’organes,',
            'les lieux d’accueil de jour des enfants d’âge préscolaire (crèches, haltes garderies),',
            'les maisons de retraite médicalisées, les centres de convalescence,',
            'les EHPAD (les maisons de retraites médicalisées relèvent du secteur résidentiel),',
            'les locaux destinés à l’hébergement des handicapés mentaux,',
            'les locaux permettant l’accueil, l’hébergement et la réadaptation de personnes souffrant d’alcoolisme ou de toxicomanie,',
            'les centres d’aides par le travail, notamment les ateliers protégés.',
        ],
    },
    {
        title: 'Autres secteurs & autres locaux :',
        sectors: [
            'les installations sportives : stades, patinoires, piscines, centres nautiques, gymnases, salles de sports ou de remise en forme, etc.,',
            'les espaces de loisirs : discothèques, casinos, cinémas, cabarets, conservatoires, écoles de musique et autres locaux destinés à l’exercice d’activités artistiques, zoos, aquariums, etc.',
            'les espaces culturels : salles de spectacles, opéras, théâtres, musées, salles d’exposition, bibliothèque, médiathèque, bâtiments inscrits au patrimoine culturel, locaux d’activité audiovisuelle, etc.,',
            'mais aussi les lieux de culte, locaux de traitement, de gestion ou d’archivage de données (sous forme papier ou sous forme informatique), data-centers et parkings.',
        ],
    },
];
