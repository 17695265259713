import { SET_DOSSIERS_FILTERS, SET_QUOTATIONS_FILTERS, SET_INVOICES_FILTERS } from '../../types/ui/filters';

const handleChangeFilterByProperty = (dispatch, filtersState, property, values, action) => {
    if (values.length === 0 || values.at(-1) === 'all') {
        dispatch(action({ ...filtersState, [property]: undefined }));
    } else {
        const filteredValues = values.filter((value) => value !== 'all');
        dispatch(action({ ...filtersState, [property]: filteredValues }));
    }
};

const setPrimeFoldersFilters = (filters) => ({
    type: SET_DOSSIERS_FILTERS,
    payload: { filters },
});

const setQuotationsFilters = (filters) => ({
    type: SET_QUOTATIONS_FILTERS,
    payload: { filters },
});

const setInvoicesFilters = (filters) => ({
    type: SET_INVOICES_FILTERS,
    payload: { filters },
});

export default { setPrimeFoldersFilters, setQuotationsFilters, setInvoicesFilters, handleChangeFilterByProperty };
