import { renovationAddressActivityAreasTranslations, renovationAddressBuildingSectorsTranslations, renovationAddressHousingTypesTranslations } from '../enums';

const renovationAddressActivityAreaOptions = Object.keys(renovationAddressActivityAreasTranslations).map((key) => ({
    value: key,
    label: renovationAddressActivityAreasTranslations[key],
}));

const renovationAddressBuildingSectorOptions = Object.keys(renovationAddressBuildingSectorsTranslations).map((key) => ({
    value: key,
    label: renovationAddressBuildingSectorsTranslations[key],
}));

const renovationAddressHousingTypeOptions = Object.keys(renovationAddressHousingTypesTranslations).map((key) => ({
    value: key,
    label: renovationAddressHousingTypesTranslations[key],
}));

export { renovationAddressActivityAreaOptions, renovationAddressBuildingSectorOptions, renovationAddressHousingTypeOptions };
