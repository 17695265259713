import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import warningIcon from '../../../../assets/img/others/warning_information.svg';
import dialogsActions from '../../../../store/actions/ui/dialogs';
import filtersActions from '../../../../store/actions/ui/filters.actions';
import { QUOTATION_VOLUME_CONTRACT_WARNING_TEST_ID } from '../../../../tests/utils/tests-ids';
import history from '../../../../utils/history';

import { SizedImg } from './common';

const Container = styled.div`
    padding-left: 1rem;
    padding-right: 1rem;
    p:first-child {
        margin-top: 1rem;
        margin-bottom: 0;
    }
    p:last-child {
        margin-bottom: 1rem;
    }
`;

export const QuotationVolumeContractOver90 = () => {
    const dispatch = useDispatch();
    const { quotations: filtersState } = useSelector((state) => state.ui.filters);

    function handleChangeFilter(property, values) {
        filtersActions.handleChangeFilterByProperty(dispatch, filtersState, property, values, filtersActions.setQuotationsFilters);
    }

    const onClickGoToFilteredQuotation = () => {
        handleChangeFilter('statuses', ['COMPLETED', 'EXPIRED']);
        dispatch(dialogsActions.hideDialogs());
        history.push('/devis');
    };
    return (
        <Container data-testid={QUOTATION_VOLUME_CONTRACT_WARNING_TEST_ID}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <SizedImg src={warningIcon} alt='' />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <p className={'bold'}>Votre devis à bien été enregistré</p>
            </div>
            <div>
                <div>
                    <span className={'font-italic'}>Cependant 90 % du volume de votre contrat obligé a été utilisé.</span>
                </div>
                <div>
                    <span className={'font-italic'}>
                        Vous pouvez supprimer les devis expirés ou complétés qui ne seront pas signés afin de libérer du volume, ou contacter votre commercial pour l'augmenter
                    </span>
                </div>
                <div>
                    <span className={'bold pointer'} onClick={onClickGoToFilteredQuotation}>
                        Cliquez ici pour allez à la liste des devis expriés et complétés
                    </span>
                </div>
            </div>
        </Container>
    );
};
