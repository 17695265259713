import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BlockCard, Button, ExpandableList, Field, Icons, TextBold } from '@renolib/renolib-ui-kit';
import _ from 'lodash';
import styled from 'styled-components';
import { v1 as uuidV1 } from 'uuid';

import CrossIcon from '../../../../assets/img/actions/cross-secondary.svg';
import { isNonEmptyObject, removeNullishProperties } from '../../../../utils';
import { renovationAddressFormFieldsDescriptor } from '../../../../utils/entity_fields_descriptors';
import { customerPMActivities, customerTypes, renovationAddressBuildingSectors, renovationAddressBuildingSectorsTranslations } from '../../../../utils/enums';
import {
    LABEL_APARTMENT_NUMBER,
    LABEL_CADASTRAL_PARCEL_NUMBER,
    LABEL_INPUT_CADASTRAL_PARCEL_NUMBER,
    LABEL_SELECT_ACTIVITY_AREA,
    LABEL_SELECT_BUILDING_SECTOR,
    LABEL_SELECT_HOUSING_TYPE,
} from '../../../../utils/form_labels';
import formValidation from '../../../../utils/form_validation';
import renovationAddressFormHelper from '../../../../utils/renovation-address-form-helper';
import renovationAddressHelper from '../../../../utils/renovation-address-helper';
import { renovationAddressActivityAreaOptions, renovationAddressBuildingSectorOptions, renovationAddressHousingTypeOptions } from '../../../../utils/select_options/renovation-address';
import Container from '../../../helpers/Container';
import Form from '../../../helpers/Form';
import { ErrorWithBadges, WarningWithBadges } from '../../../helpers/MessageWithBadges';
import useForm from '../../../hooks/useForm';
import useModal from '../../../hooks/useModal';
import { DisplayInvalidAddressForm } from '../../misc/finders/InvalidAddressForm';
import SectorsActivityModal from '../../misc/renovation_addresses/SectorsActivityModal';

const ExpandleListItem = styled(ExpandableList.Item)`
    main {
        z-index: 10;
        position: relative;
        overflow-y: visible;
    }
`;

const CompanyCard = styled.div`
    background: #ecf0f1;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 25px;
    transition: 0.3s;
    &:hover {
        position: relative;
        z-index: 100;
        transform: scale(1.02);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
`;

const CompanyName = styled.h2`
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin: 0 0 8px 0;
`;

const CompanyAddress = styled.div`
    font-size: 16px;
    color: #666;
    margin-bottom: 4px;
`;

const CompanyDetails = styled.div`
    font-size: 16px;
    color: #666;
    font-style: italic;
`;

const WrapperActivityArea = styled.div`
    position: relative;
    > span {
        position: absolute;
        left: 22%;
        text-decoration: underline;
        color: #102339;
        font-weight: 500;
        cursor: pointer;
    }
`;

function displayLabelCadastralParcelNumber({ postalCode = '' }) {
    return postalCode && postalCode.slice(0, 2) === '97' ? LABEL_CADASTRAL_PARCEL_NUMBER : LABEL_INPUT_CADASTRAL_PARCEL_NUMBER;
}

const CustomFormForTertiaryAddress = ({
    availableBuildingSectors = [],
    customerActivity,
    sectorsActivityModal,
    mainAddressActivitySector,
    address,
    errors,
    onChange,
    withInvalidAddressForm = false,
}) => {
    const displayAttestationDeDomiciliation = useMemo(() => {
        if ([customerPMActivities.OTHER].includes(customerActivity)) {
            return true;
        }
        if (
            [customerPMActivities.SOCIAL_LANDLORD, customerPMActivities.OWNER_TENANT, customerPMActivities.COOWNED_SYNDICATE, customerPMActivities.SCI].includes(customerActivity) &&
            address.isCustom &&
            address.buildingSector === renovationAddressBuildingSectors.TERTIARY
        ) {
            return true;
        }
        return false;
    }, [customerActivity, address]);

    const displayAddressSwornStatement = useMemo(() => {
        if (address.buildingSector === renovationAddressBuildingSectors.RESIDENTIAL) {
            return false;
        }

        if (!mainAddressActivitySector) return false;
        return ![customerPMActivities.SCI].includes(customerActivity) && address.buildingSector === renovationAddressBuildingSectors.TERTIARY && mainAddressActivitySector !== address.activityArea;
    }, [customerActivity, address.buildingSector, address.activityArea, mainAddressActivitySector]);

    const displayRentReceipt = useMemo(() => {
        return [customerPMActivities.SCI].includes(customerActivity) && address.buildingSector === renovationAddressBuildingSectors.TERTIARY;
    }, [customerActivity, address.buildingSector]);

    return (
        <>
            <Form.Group>
                <Field fluid type='text' label="NOM DE L'ADRESSE" name='addressName' onChange={onChange} error={errors?.addressName} defaultValue={address?.addressName} />
            </Form.Group>
            {withInvalidAddressForm && (
                <DisplayInvalidAddressForm validAddress={false} addressFormProps={{ formState: address, formErrors: errors, onChange: onChange }} displayTextConfig={{ displayText: false }} />
            )}
            <Form.Group>
                <Field
                    fluid
                    type='text'
                    name='cadastralParcelNumber'
                    label={displayLabelCadastralParcelNumber(address)}
                    defaultValue={address?.cadastralParcelNumber}
                    error={errors?.cadastralParcelNumber}
                    onChange={onChange}
                />
            </Form.Group>
            <Form.Group>
                <Field
                    fluid
                    type='select'
                    lightBackground
                    name='buildingSector'
                    disabled={!!address?.buildingSector && !address.isCustom}
                    label={LABEL_SELECT_BUILDING_SECTOR}
                    options={renovationAddressBuildingSectorOptions.filter(({ value }) => availableBuildingSectors.includes(value))}
                    defaultValue={_.get(renovationAddressBuildingSectorsTranslations, address.buildingSector)}
                    error={errors?.buildingSector}
                    onChange={onChange}
                />
            </Form.Group>
            {address.buildingSector === renovationAddressBuildingSectors.TERTIARY && (
                <Form.Group>
                    <Field
                        fluid
                        type='number'
                        lightBackground
                        name='siret'
                        disabled={!address.isCustom}
                        label={"SIRET de l'établissement"}
                        defaultValue={address?.siret}
                        error={errors?.siret}
                        onChange={onChange}
                    />
                </Form.Group>
            )}
            {renovationAddressFormHelper.canDisplayHousingTypeSelect(address) && (
                <Form.Group>
                    <Field
                        fluid
                        type='select'
                        name='housingType'
                        lightBackground
                        label={LABEL_SELECT_HOUSING_TYPE}
                        options={renovationAddressHousingTypeOptions}
                        defaultValue={renovationAddressFormHelper.translateHousingType(address)}
                        error={errors?.housingType}
                        onChange={onChange}
                    />
                </Form.Group>
            )}
            {renovationAddressHelper.isApartment(address) && (
                <Form.Group>
                    <Field fluid type='text' name='apartmentNumber' label={LABEL_APARTMENT_NUMBER} defaultValue={address?.apartmentNumber} error={errors?.apartmentNumber} onChange={onChange} />
                </Form.Group>
            )}
            {renovationAddressFormHelper.canDisplayActivityAreaSelect(address) && (
                <Form.Group>
                    <WrapperActivityArea>
                        <span onClick={sectorsActivityModal?.handleShow}>Comment le définir ?</span>
                        <Field
                            fluid
                            type='select'
                            lightBackground
                            name='activityArea'
                            label={LABEL_SELECT_ACTIVITY_AREA}
                            options={renovationAddressActivityAreaOptions}
                            defaultValue={renovationAddressFormHelper.translateActivityArea(address)}
                            error={errors?.activityArea}
                            onChange={onChange}
                        />
                    </WrapperActivityArea>
                </Form.Group>
            )}
            {displayAttestationDeDomiciliation && (
                <div>
                    <TextBold color='primary' fontWeight={600}>
                        Une attestation de domiciliation de votre client vous sera demandée dans le dossier.
                    </TextBold>
                    <br />
                </div>
            )}
            {displayAddressSwornStatement && (
                <div>
                    <TextBold color='primary' fontWeight={600}>
                        Une attestation sur l’honneur de votre client vous sera demandée dans le dossier.
                    </TextBold>
                    <br />
                </div>
            )}
            {displayRentReceipt && (
                <div>
                    <TextBold color='primary' fontWeight={600}>
                        Un justificatif d'occupation (quittance de loyer) vous sera demandée dans le dossier
                    </TextBold>
                    <br />
                </div>
            )}
        </>
    );
};

const AddACustomerAddressForCustomerPM = ({ customerPM, mainEtablissement, sectorsActivityModal, addAnAddress, options }) => {
    const addressForm = useForm({ defaultFormState: { isCustom: true } });

    const onSubmit = () => {
        const state = renovationAddressFormHelper.updateFormStateUsingBuilingSector(removeNullishProperties(addressForm.state));
        const { validForm, formErrors } = formValidation.validateForm(
            state,
            [
                ...renovationAddressFormFieldsDescriptor,
                {
                    property: 'siret',
                    label: 'SIRET',
                    type: 'number',
                    validate: (formState, formError) => {
                        if (formState.buildingSector === renovationAddressBuildingSectors.TERTIARY) {
                            if (!formState.siret) {
                                formError.siret = 'Le siret doit être définis';
                                return false;
                            }

                            if (formState.siret && `${formState.siret}`.length !== 14) {
                                formError.siret = 'Le siret doit être composé de 14 chiffres';
                                return false;
                            }
                        }
                        return true;
                    },
                },
            ],
            { customerType: customerTypes.CUSTOMER_PM }
        );

        addressForm.setErrors(formErrors);
        if (validForm) {
            addAnAddress(state);
        }
    };

    return (
        <>
            <CustomFormForTertiaryAddress
                sectorsActivityModal={sectorsActivityModal}
                availableBuildingSectors={options?.availableBuildingSectors}
                customerActivity={customerPM?.details?.activity}
                mainAddressActivitySector={_.get(mainEtablissement, 'activityArea')}
                address={addressForm.state}
                errors={addressForm.errors}
                onChange={addressForm.handleChange}
                withInvalidAddressForm={true}
            />
            <div className={'flex-row justify-content-center pt-2'}>
                <Button lightMode={'1'} disabled={false} onClick={onSubmit} className='color-white'>
                    Ajouter cette adresse
                </Button>
            </div>
            <div className={'flex-row justify-content-center pt-2 mb-4 mt-2'}>
                <WarningWithBadges message={"N'oubliez pas de cliquer sur 'Ajouter cette adresse' avant de valider l'ensemble du formulaire'"} />
            </div>
        </>
    );
};

export const CustomerPmAddresses = ({
    customerPM,
    onSubmit,
    isManualMode = false,
    submitText,
    isLoading,
    mainCustomerEtablissement = undefined,
    options = {
        textOverloads: {},
        withSiegeSocialConfig: false,
        isSiegeSocialObligatory: true,
        availableBuildingSectors: [renovationAddressBuildingSectors.TERTIARY, renovationAddressBuildingSectors.RESIDENTIAL],
    },
}) => {
    const { withSiegeSocialConfig, isSiegeSocialObligatory } = options;
    const [displayManualAddressForm, setDisplayManualAddressForm] = useState(false);
    const sectorsActivityModal = useModal();

    const [organisationAddresses, setOrganizationAddresses] = useState(customerPM?.etablissements || []);
    const [errors, setErrors] = useState(undefined);

    const onSubmitAddresses = useCallback(() => {
        let errors = organisationAddresses.reduce((acc, etablissement) => {
            if (etablissement.addAsRenovationAddress) {
                //activityArea
                if (!etablissement.addressName) {
                    acc = { ...acc, [`${etablissement.uuid}`]: { ..._.get(acc, etablissement.uuid, {}), addressName: `Le nom de l'établissement est obligatoire` } };
                }
                if (etablissement.buildingSector === renovationAddressBuildingSectors.TERTIARY && !etablissement.activityArea) {
                    acc = { ...acc, [`${etablissement.uuid}`]: { ..._.get(acc, etablissement.uuid, {}), activityArea: `${LABEL_SELECT_ACTIVITY_AREA} est obligatoire` } };
                }
                if (!etablissement.buildingSector) {
                    acc = { ...acc, [`${etablissement.uuid}`]: { ..._.get(acc, etablissement.uuid, {}), buildingSector: `${LABEL_SELECT_BUILDING_SECTOR} est obligatoire` } };
                }
                if (
                    (etablissement.isCustom && !etablissement.streetNumber && !etablissement.cadastralParcelNumber) ||
                    (!etablissement.isCustom && !etablissement.containStreetNumber && !etablissement.cadastralParcelNumber)
                ) {
                    acc = { ...acc, [`${etablissement.uuid}`]: { ..._.get(acc, etablissement.uuid, {}), cadastralParcelNumber: `${LABEL_CADASTRAL_PARCEL_NUMBER} est obligatoire` } };
                }
                if (etablissement.isCustom && etablissement.buildingSector === renovationAddressBuildingSectors.TERTIARY) {
                    if (!etablissement.siret) {
                        acc = { ...acc, [`${etablissement.uuid}`]: { ..._.get(acc, etablissement.uuid, {}), siret: `Le siret est obligatoire` } };
                    }
                    if (etablissement.siret && `${etablissement.siret}`.length !== 14) {
                        acc = { ...acc, [`${etablissement.uuid}`]: { ..._.get(acc, etablissement.uuid, {}), siret: 'Le siret doit être composé de 14 chiffres' } };
                    }
                }
            }
            if (etablissement.etablissementSiege && !etablissement.activityArea) {
                acc = { ...acc, [`${etablissement.uuid}`]: { ..._.get(acc, etablissement.uuid, {}), activityArea: `Le secteur d'activité est obligatoire pour l'établissement siège` } };
            }
            return acc;
        }, {});

        if (withSiegeSocialConfig && isSiegeSocialObligatory && !organisationAddresses.find(({ etablissementSiege }) => !!etablissementSiege)) {
            errors = {
                ...errors,
                global: {
                    ..._.get(errors, 'global', {}),
                    etablissementSiege: "Vous devez au moins choisir un siège d'établissement (Ce choix ne peut être réalisé que sur des adresses dont le secteur d'activité est de type tertiaire)",
                },
            };
        }

        if (isNonEmptyObject(errors)) {
            setErrors(errors);
            return;
        } else {
            setErrors({});
        }
        onSubmit(organisationAddresses);
    }, [organisationAddresses, onSubmit, withSiegeSocialConfig, isSiegeSocialObligatory]);

    const updateEtablissement = useCallback(
        ({ path, value, uuid }) => {
            let addressToUpdate = [...organisationAddresses];

            if (path === 'etablissementSiege') {
                addressToUpdate = addressToUpdate.map((etablissement) => ({ ...etablissement, etablissementSiege: false }));
            }

            const index = addressToUpdate.findIndex((etablissement) => etablissement.uuid === uuid);
            _.set(addressToUpdate, `${index}.${path}`, value);
            if (path === 'etablissementSiege' && isManualMode) {
                _.set(addressToUpdate, `${index}.siret`, _.get(customerPM, 'details.siret'));
            }
            if (path === 'etablissementSiege') {
                const defaultAddressNameValue = _.get(addressToUpdate, `${index}.addressName`, _.get(addressToUpdate, `${index}.uniteLegale.denominationUniteLegale`));
                //if siege the name of the address will be the one of the company
                _.set(addressToUpdate, `${index}.addressName`, defaultAddressNameValue);
            }

            if (path === 'addAsRenovationAddress' && !value) {
                // for reset
                const isSiege = _.get(addressToUpdate, `${index}.etablissementSiege`);
                _.set(addressToUpdate, `${index}.addressName`, isSiege ? _.get(addressToUpdate, `${index}.uniteLegale.denominationUniteLegale`) : _.get(addressToUpdate, `${index}.addressName`));
            }
            setOrganizationAddresses(addressToUpdate);
        },
        [organisationAddresses, setOrganizationAddresses, isManualMode, customerPM]
    );

    const onAddACustomerAddress = useCallback(
        (address) => {
            let addressToUpdate = [...organisationAddresses];
            addressToUpdate.push({ ...address, uuid: uuidV1(), addAsRenovationAddress: true, etablissementSiege: false, isCustom: true });
            setOrganizationAddresses(addressToUpdate);
            setDisplayManualAddressForm(false);
        },
        [organisationAddresses, setOrganizationAddresses]
    );

    const onDelete = useCallback(
        ({ uuid }) => {
            setOrganizationAddresses(organisationAddresses.filter((etablissement) => etablissement.uuid !== uuid));
        },
        [organisationAddresses, setOrganizationAddresses]
    );

    const mainEtablissement = useMemo(() => {
        return mainCustomerEtablissement || organisationAddresses.find(({ etablissementSiege }) => etablissementSiege);
    }, [organisationAddresses, mainCustomerEtablissement]);

    const cannotSelectEtablissementSiege = useMemo(() => {
        if (!customerPM) return false;
        return customerPM?.etablissements?.some(({ etablissementSiege }) => etablissementSiege);
    }, [customerPM]);

    useEffect(() => {
        if (!customerPM || isLoading) {
            return;
        }
        if (!customerPM.etablissements || customerPM.etablissements.length === 0) {
            setDisplayManualAddressForm(true);
            return;
        }
        setOrganizationAddresses(customerPM.etablissements);
        setDisplayManualAddressForm(false);
    }, [customerPM, setOrganizationAddresses, setDisplayManualAddressForm, isLoading]);

    return (
        <>
            {organisationAddresses.map((etablissement) => {
                return (
                    <CompanyCard key={`adresse-travaux-${etablissement.uuid}`}>
                        <div className={'flex-row justify-content-between'}>
                            <CompanyName>{etablissement.addressName || etablissement.addressName}</CompanyName>
                            <div className={'flex-row justify-content-between align-items-center'}>
                                {etablissement.siret && <CompanyDetails>SIRET : {etablissement.siret}</CompanyDetails>}
                                {etablissement.isCustom && <img style={{marginLeft:'10px', width:'20px'}} className={'pointer'} src={CrossIcon} alt='' onClick={() => onDelete({ uuid: etablissement.uuid })} />}
                            </div>
                        </div>

                        <CompanyAddress>{`${etablissement.streetNumber || ''} ${etablissement.streetName}, ${etablissement.postalCode} ${etablissement.city}`}</CompanyAddress>

                        {options?.withSiegeSocialConfig && etablissement.buildingSector === renovationAddressBuildingSectors.TERTIARY && (
                            <div className={'flex-row'}>
                                <Field
                                    type='checkbox'
                                    label={'Cet adresse est le siège de mon établissement'}
                                    disabled={cannotSelectEtablissementSiege}
                                    checked={etablissement.etablissementSiege}
                                    onChange={({ value }) => updateEtablissement({ path: 'etablissementSiege', value, uuid: etablissement.uuid })}
                                />
                            </div>
                        )}
                        <div className={'flex-row'}>
                            <Field
                                type='checkbox'
                                label={options?.textOverloads?.addAsRenovationAddress || 'Je veux ajouter cette adresse comme adresse de travaux'}
                                disabled={etablissement.isCustom}
                                checked={!!etablissement.addAsRenovationAddress}
                                onChange={({ value }) => updateEtablissement({ path: 'addAsRenovationAddress', value, uuid: etablissement.uuid })}
                            />
                        </div>
                        {!etablissement.addAsRenovationAddress && options?.withSiegeSocialConfig && etablissement.etablissementSiege && (
                            <Form.Group>
                                <WrapperActivityArea>
                                    <span onClick={sectorsActivityModal?.handleShow}>Comment le définir ?</span>
                                    <Field
                                        fluid
                                        type='select'
                                        lightBackground
                                        name='activityArea'
                                        label={LABEL_SELECT_ACTIVITY_AREA}
                                        options={renovationAddressActivityAreaOptions}
                                        defaultValue={renovationAddressFormHelper.translateActivityArea(etablissement)}
                                        error={_.get(errors, `${etablissement.uuid}.activityArea`)}
                                        onChange={({ value }) => updateEtablissement({ path: 'activityArea', value, uuid: etablissement.uuid })}
                                    />
                                </WrapperActivityArea>
                            </Form.Group>
                        )}
                        {etablissement.addAsRenovationAddress && (
                            <ul>
                                <ExpandleListItem
                                    key={etablissement.uuid}
                                    viewable={true}
                                    selectedIndex={0}
                                    index={0}
                                    header={
                                        <div className={'flex-row align-items-center'}>
                                            <span>A remplir</span>
                                            {_.get(errors, etablissement.uuid) && (
                                                <span className={'ml-2'} role={'img'} aria-label={'red-critical-alert-dot'}>
                                                    🔴
                                                </span>
                                            )}
                                        </div>
                                    }
                                    main={
                                        <CustomFormForTertiaryAddress
                                            sectorsActivityModal={sectorsActivityModal}
                                            customerActivity={customerPM?.details?.activity}
                                            mainAddressActivitySector={_.get(mainEtablissement, 'activityArea')}
                                            availableBuildingSectors={options?.availableBuildingSectors}
                                            errors={_.get(errors, etablissement.uuid)}
                                            onChange={({ name, value }) => {
                                                updateEtablissement({ path: name, value, uuid: etablissement.uuid });
                                            }}
                                            withInvalidAddressForm={etablissement.isCustom}
                                            address={etablissement}
                                        />
                                    }
                                />
                            </ul>
                        )}
                    </CompanyCard>
                );
            })}
            <div
                className={'flex-row justify-content-center pt-2'}
                onClick={() => {
                    setDisplayManualAddressForm(!displayManualAddressForm);
                }}>
                <p className='pointer color-sea-green fs-16'>Je veux ajouter un établissement qui n'est pas présent dans la liste</p>
            </div>

            {displayManualAddressForm && (
                <CompanyCard>
                    <AddACustomerAddressForCustomerPM
                        customerPM={customerPM}
                        options={options}
                        mainEtablissement={mainEtablissement}
                        sectorsActivityModal={sectorsActivityModal}
                        addAnAddress={onAddACustomerAddress}
                    />
                </CompanyCard>
            )}
            <div className={'flex-row justify-content-center pt-2'}>
                <Button lightMode={'1'} disabled={false} onClick={onSubmitAddresses} className='color-white'>
                    {submitText}
                </Button>
            </div>
            {errors?.global &&
                Object.keys(errors.global).map((key) => {
                    return <ErrorWithBadges message={errors.global[key]} fontSize={'fs-14'} />;
                })}
            {sectorsActivityModal?.show && <SectorsActivityModal show={sectorsActivityModal.show} onHide={sectorsActivityModal.handleHide} />}
        </>
    );
};

export const SetCustomersAddresses = ({ customerPM, onSubmit, isManualMode, options = { withSiegeSocialConfig: true }, isLoading }) => {
    return (
        <Container backButtonTitle='Retour à la recherche du siren' backUrl={customerPM && customerPM?.siren ? `/creer-client-professionnel/2/${customerPM.siren}` : '/creer-client-professionnel/1'}>
            <div className={'flex-row justify-content-center'}>
                <BlockCard title='Adresse de travaux' icon={Icons.blockPlace}>
                    <div className={'mb-4'}>
                        <TextBold color='primary' fontWeight={600}>
                            {isManualMode
                                ? "Vous devez au moins renseigner votre siège sociale. Pour cela, ajouter d'abord un établissement"
                                : `Vous pouvez passer cette étape, vous aurez la possibilité d'ajouter les adresses de travaux plus tard`}
                        </TextBold>
                    </div>
                    <CustomerPmAddresses customerPM={customerPM} onSubmit={onSubmit} isManualMode={isManualMode} options={options} submitText={'Suivant'} isLoading={isLoading} />
                </BlockCard>
            </div>
        </Container>
    );
};
