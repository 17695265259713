import styled from 'styled-components';
import colors from '../../config/colors';

export default styled.span`
    display: block;
    color: ${colors.primary};
    font-weight: 600;
    text-align: center;
    margin: 0.75rem 0 1.5rem 0;
`;
