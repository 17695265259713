import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BlockCard, Button, Icons } from '@renolib/renolib-ui-kit';
import { useMutation } from '@tanstack/react-query';
import _ from 'lodash';

import formsActions from '../../../../store/actions/ui/form';
import customerPmService from '../../../../store/services/customer-pm.service';
import administrativeContactFormHelper from '../../../../utils/administrative-contact-form-helper';
import { isEmailDisposable } from '../../../../utils/email-verification-helper';
import formValidation from '../../../../utils/form_validation';
import { UNAUTHORIZED_EMAIL } from '../../../../utils/messages';
import phoneFormValidation from '../../../../utils/phone-form-validation';
import Container from '../../../helpers/Container';
import { ErrorWithBadges } from '../../../helpers/MessageWithBadges';
import useForm from '../../../hooks/useForm';
import ContractForm from '../../misc/ContactForm';

export const SetCustomersContact = ({ onSubmit, createCustomerError }) => {
    const { state, errors, handleChange, setErrors } = useForm();
    const dispatch = useDispatch();
    const [canCreateError, setCanCreateError] = useState();

    const handleSuccess = useCallback(() => {
        setCanCreateError();
        onSubmit(state);
    }, [state, onSubmit]);

    const handleError = (error) => {
        const message = _.get(error, 'response.data.message');
        setCanCreateError(message || 'Une erreur est survenue');
    };

    const onCanCreateCustomerPMContacts = useMutation({
        mutationFn: customerPmService.canCreateACustomerPMContact,
        onMutate() {
            dispatch(formsActions.startRequesting());
        },
        onSuccess: handleSuccess,
        onError: handleError,
        onSettled() {
            dispatch(formsActions.stopRequesting());
        },
    });

    const performContactFormValidation = async () => {
        let { validForm, formErrors } = formValidation.validateForm(state, administrativeContactFormHelper.getTargetContactFormFieldsDescriptor(state), state, phoneFormValidation.validatePhoneForm);
        if (state.email) {
            const isDisposableEmail = await isEmailDisposable(state.email);
            if (isDisposableEmail) {
                validForm = false;
                formErrors = {
                    ...formErrors,
                    email: UNAUTHORIZED_EMAIL,
                };
            }
        }

        const formattedMainContactFormErrors = administrativeContactFormHelper.formatMainContactFormErrors(formErrors);
        setErrors(formattedMainContactFormErrors);

        return { formState: { isMainContact: false, ...state }, validForm };
    };

    const onSubmitContacts = async () => {
        const { validForm, formState } = await performContactFormValidation();

        if (validForm) {
            onCanCreateCustomerPMContacts.mutate({ data: { contacts: [formState] } });
        }
    };

    return (
        <Container backButtonTitle='Retour à la recherche du siren' backUrl={`/creer-client-professionnel/3`}>
            <div className={'flex-row justify-content-center'}>
                <div className={'w-50'}>
                    <BlockCard title='Ajouter un contact' icon={Icons.blockPlace}>
                        <ContractForm formState={state} formErrors={errors} onChange={handleChange} isOrganization={false} />
                        <div className={'flex-row justify-content-center pt-2'}>
                            <Button lightMode={'1'} disabled={false} onClick={onSubmitContacts} className='color-white'>
                                Suivant
                            </Button>
                        </div>
                        {(canCreateError || createCustomerError) && <ErrorWithBadges message={canCreateError || createCustomerError} fontSize={'fs-12'} />}
                    </BlockCard>
                </div>
            </div>
        </Container>
    );
};
