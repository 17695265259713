import { isObjectEmpty, isValidValue } from '.';
import { NEED_TO_PROVIDE_AT_LEAST_ONE_PHONE_NUMBER } from './messages';
import { administrativeContactFieldsDescriptor, administrativeMainContactFieldsDescriptor } from '../utils/entity_fields_descriptors';

const mainContactRequiredProperties = ['lastName', 'firstName', 'role', 'email'];
const mainContactPhoneProperties = ['phone', 'mobile'];

function isMainContactComplete(organization, formattedOrganization = true) {
    const mainContact = extractMainContactFromOrganization(organization, formattedOrganization);
    if (isObjectEmpty(mainContact)) return false;

    return mainContactRequiredProperties.every((property) => isValidValue(mainContact[property])) && mainContactPhoneProperties.some((property) => isValidValue(mainContact[property]));
}

function extractMainContactFromOrganization(organization, formattedOrganization = true) {
    if (isObjectEmpty(organization)) return {};
    const organizationContacts = organization[formattedOrganization ? 'organizationContacts' : 'contacts'] || [];

    return organizationContacts.find((contact) => contact.isMainContact) || {};
}

function getCompleteMainContactModalSaveButtonTitle(params) {
    return params.saveButtonTitle || 'Enregistrer';
}

function formatMainContactFormErrors(mainContactFormErrors) {
    const formattedMainContactFormErrors = { ...mainContactFormErrors };
    if (isMainContactFormErrorsRelatedToPhoneFields(mainContactFormErrors)) {
        formattedMainContactFormErrors.mobile = NEED_TO_PROVIDE_AT_LEAST_ONE_PHONE_NUMBER;
    }

    return formattedMainContactFormErrors;
}

function isMainContactFormErrorsRelatedToPhoneFields(mainContactFormErrors) {
    return mainContactPhoneProperties.every((property) => isValidValue(mainContactFormErrors[property]));
}

function getTargetContactFormFieldsDescriptor(contactFormState = {}) {
    return contactFormState.isMainContact ? administrativeMainContactFieldsDescriptor : administrativeContactFieldsDescriptor;
}

function updateContactsWithMainContact(contacts = [], updatedMainContact) {
    const mainContactValid = isValidValue(contacts.find((contact) => contact.isMainContact));
    if (mainContactValid) return contacts.map((contact) => (contact.isMainContact ? updatedMainContact : contact));

    contacts.push(updatedMainContact);

    return contacts;
}

export default {
    isMainContactComplete,
    extractMainContactFromOrganization,
    getCompleteMainContactModalSaveButtonTitle,
    formatMainContactFormErrors,
    getTargetContactFormFieldsDescriptor,
    updateContactsWithMainContact,
};
