import React, { useState } from 'react';
import { Field } from '@renolib/renolib-ui-kit';

import Form from '../../../helpers/Form';

export const WithApiController = ({ children, onUpdateApiMode }) => {
    const options = [
        {
            label: 'Normal',
            value: 'NORMAL',
        },
        { label: 'Crash INSEE', value: 'CRASH_INSEE' },
        { label: 'La limite INSEE est dépassé', value: 'INSEE_LIMIT' },
        { label: "Le secteur d'activité d'une adresse tertiaire n'est pas récupéré de l'Insee", value: 'NO_PREDEFINE_ACTIVITY_SECTOR' },
        { label: 'Insee fonctionne mais pas société info (pour le numero de tva)', value: 'INSEE_OK_BUT_NOT_SOCIETY_INFO' },
    ];

    const [apiMode, setApiMode] = useState(options[0].value);

    const _setApiMode = (mode) => {
        onUpdateApiMode(mode);
        setApiMode(mode);
    };

    return (
        <>
            {false && (
                <Form.Group>
                    <Field
                        fluid
                        type='select'
                        lightBackground
                        name='apiMode'
                        label={'Sélectionner le mode de votre API'}
                        options={options}
                        defaultValue={options.find(({ value }) => value === apiMode).label}
                        onChange={({ value }) => _setApiMode(value)}
                    />
                </Form.Group>
            )}
            {children}
        </>
    );
};
