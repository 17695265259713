import React from 'react';
import styled from 'styled-components';
import activitySectors from '../../../../data/activity-sectors';

const UnStyledContentSectorsActivity = ({ className }) => {
    return (
        <ul className={className}>
            {activitySectors.map((activity) => (
                <li key={activity.title}>
                    <b>{activity.title}</b>
                    <ul>
                        {activity.sectors.map((sector) => (
                            <li key={sector}>{sector}</li>
                        ))}
                    </ul>
                </li>
            ))}
        </ul>
    );
};

const StyledContentSectorsActivity = styled(UnStyledContentSectorsActivity)`
    > li {
        padding: 5px 0;
        > b {
            font-size: 14px;
        }
        > ul {
            padding: 0 6px;
            > li {
                list-style: inside !important;
            }
        }
    }
`;

function ContentSectorsActivity(props) {
    return <StyledContentSectorsActivity {...props} />;
}

export default ContentSectorsActivity;
