import _ from 'lodash';

import dimensions from '../config/dimensions';

import { batTh142VA543AndProductsLabels } from './operations-sheets-labels/bat_th_142v_a54_3';
import dateUtils from './date_utils';
import entitiesUtils from './entities-utils';
import { dossierCEEStatuses, invoiceStatuses, representativeType, uploadDocumentFileTypes, uploadDocumentTypes, uploadDocumentTypesTranslations, uploadDocumentUploadSources } from './enums';
import { LABEL_INPUT_HEATING_PUMP_COVERAGE_RATE } from './form_labels';
import { UPLOAD_DOCUMENT_NOT_DELETABLE_DOSSIER_CEE_SENT, UPLOAD_DOCUMENT_NOT_DELETABLE_INVOICED } from './messages';
import operationHelper from './operation-helper';
import operationSheetNames from './operation-sheet-names';
import quotationHelper from './quotation-helper';
import { isOperationOfRenovationGlobal } from './renovation-global-helper';
import { isNonEmptyObject, isNullishOrEmpty, isValidValue } from '.';

function getDocumentTypeLabel(documentType) {
    return uploadDocumentTypesTranslations[documentType] || '';
}

function formatUploadDocumentsForTable(uploadDocuments = []) {
    return uploadDocuments.map((uploadDocument) => {
        const { uuid, createdAt, signatureDate, documentType, fileName, account, uploadSource } = uploadDocument;

        return {
            uuid,
            documentType: getDocumentTypeLabel(documentType),
            fileName: getFilename({ documentType, fileName, sitePhotos: uploadDocument?.sitePhotos }),
            uploadDocument,
            customerName: quotationHelper.formatCustomerName(extractQuotationFromUploadDocument(uploadDocument)?.customer),
            signatureDate,
            createdAt,
            accountName: uploadSource === uploadDocumentUploadSources.CUSTOMER ? 'Client' : entitiesUtils.getFullNameText(account),
            operations: quotationHelper.getOperationsNames(extractQuotationFromUploadDocument(uploadDocument)?.operations),
        };
    });
}

function getFilename({ documentType, fileName, sitePhotos = [] }) {
    const isSitePhoto = [uploadDocumentTypes.SITE_PHOTO_BEFORE_RENOVATION, uploadDocumentTypes.SITE_PHOTO_AFTER_RENOVATION].includes(documentType);
    let text = 'photo';

    if (sitePhotos.length > 1) text = text.replace('photo', 'photos');
    if (isSitePhoto)
        return {
            [uploadDocumentTypes.SITE_PHOTO_BEFORE_RENOVATION]: `${sitePhotos.length} ${text} avant travaux`,
            [uploadDocumentTypes.SITE_PHOTO_AFTER_RENOVATION]: `${sitePhotos.length} ${text} après travaux`,
        }[documentType];

    return fileName;
}

function extractQuotationFromUploadDocument({ dossierCEE, quotation }) {
    return isNonEmptyObject(quotation) ? quotation : dossierCEE?.quotation;
}

function formatSignatureDate(signatureDate) {
    return signatureDate ? entitiesUtils.formatDate(signatureDate) : 'Non renseignée';
}

function formatCreationDate(createdAt) {
    return entitiesUtils.formatDate(createdAt);
}

function formatFileName(fileName) {
    const lastIndexOfDot = fileName.lastIndexOf('.');

    return fileName
        .trim()
        .substring(0, lastIndexOfDot)
        .replace(/[-, _, \\., ]/g, ' ')
        .toUpperCase();
}

function formatFileType(fileType) {
    return fileType.includes('pdf') ? uploadDocumentFileTypes.PDF : uploadDocumentFileTypes.IMAGE;
}

function extractFileNameAndFileType(file) {
    const { name, type } = file;

    return {
        fileName: formatFileName(name),
        fileType: formatFileType(type),
    };
}

function isCertificateOfCompliancy(documentType) {
    return documentType === uploadDocumentTypes.CERTIFICATE_OF_COMPLIANCY;
}
function isPostRenovationReport(documentType) {
    return documentType === uploadDocumentTypes.POST_RENOVATION_REPORT;
}

function isDpeOrAuditEnergetic(documentType) {
    return documentType === uploadDocumentTypes.DPE_OR_ENERGETIC_AUDIT;
}
function isTaxNotice(documentType) {
    return documentType === uploadDocumentTypes.TAX_NOTICE;
}

function isSignatureDateRequired(documentType) {
    return [
        uploadDocumentTypes.QUOTATION,
        uploadDocumentTypes.SWORN_STATEMENT,
        uploadDocumentTypes.POST_RENOVATION_REPORT,
        uploadDocumentTypes.RENOVATION_GLOBAL_ADVISED_OPERATIONS_LIST,
        uploadDocumentTypes.RENOVATION_GLOBAL_ASSISTANCE_CONTRACT_AMO_REFUSE,
        uploadDocumentTypes.RENOVATION_GLOBAL_MAPRIMERENOV_WORKING_STATEMENT,
        uploadDocumentTypes.RENOVATION_GLOBAL_AUDIT_QUOTATION,
        uploadDocumentTypes.SUMMARY_STATEMENT,
    ].includes(documentType);
}

function isPDFDocument({ fileType }) {
    return fileType === uploadDocumentFileTypes.PDF;
}

function getUploadDocumentDossierCEEOptions(dossiersCEE, documentType) {
    return dossiersCEE
        .filter((dossierCEE) => (documentType !== uploadDocumentTypes.QUOTATION ? isValidValue(dossierCEE.number) : true))
        .map((dossierCEE) => {
            const { quotationNumber, customer, operations } = dossierCEE.quotation;
            const customerName = quotationHelper.formatCustomerName(customer);
            const operationNamePart = operations.length > 0 ? ' - ' + quotationHelper.formatOperationSheetName(operations[0].operationSheetName) : '';

            return { label: `${customerName} - Devis n° ${quotationNumber} ${operationNamePart}`, value: dossierCEE._id };
        });
}

function isDeletable(uploadDocument) {
    const { deletable = true } = uploadDocument;

    return !isDossierCEESent(uploadDocument) && isDeletableDocument(uploadDocument) && deletable;
}

function isDossierCEESent(uploadDocument) {
    const dossierCEEStatus = _.get(uploadDocument, 'dossierCEE.status');

    return dossierCEEStatus === dossierCEEStatuses.SENT;
}

function isDeletableDocument(uploadDocument) {
    const invoiceStatus = _.get(uploadDocument, 'dossierCEE.invoice.status');

    return uploadDocument.documentType === uploadDocumentTypes.QUOTATION ? !isInvoiceStatusValid(invoiceStatus) : true;
}

function isInvoiceStatusValid(status) {
    return [invoiceStatuses.FINALIZED, invoiceStatuses.PAID, invoiceStatuses.SENT].includes(status);
}

function isQuotationEnergySavingCertificatePremiumValid(uploadDocument) {
    return extractQuotationFromUploadDocument(uploadDocument)?.energySavingCertificatePremium > 0;
}

function getUploadDocumentDeleteButtonMessage(uploadDocument = {}) {
    return isDossierCEESent(uploadDocument) ? UPLOAD_DOCUMENT_NOT_DELETABLE_DOSSIER_CEE_SENT : !isDeletableDocument(uploadDocument) ? UPLOAD_DOCUMENT_NOT_DELETABLE_INVOICED : '';
}

function canDisplayRenovationAddressAsText({ postalCode, city } = {}) {
    return isValidValue(postalCode) || isValidValue(city);
}

function getRenovationAddressAsText({ postalCode, city } = {}) {
    const renovationAddressTextParts = [];
    if (isValidValue(postalCode)) renovationAddressTextParts.push(postalCode);
    if (isValidValue(city)) renovationAddressTextParts.push(city);

    return renovationAddressTextParts.join(', ');
}

function getOperationsNames(operations = []) {
    return quotationHelper.getOperationsNames(operations).map(({ name }) => name);
}

function getSelectDocumentTypeOptions(operationSheets = []) {
    const uploadDocumentTypeOptions = Object.values(uploadDocumentTypes)
        .filter((documentType) => ![uploadDocumentTypes.OTHER, uploadDocumentTypes.SOLAR_FACTOR_CALCULATION_NOTE, uploadDocumentTypes.SIZING_NOTE].includes(documentType))
        .map(formatSelectDocumentTypeOption)
        .sort((prevOption, nextOption) => prevOption.label.localeCompare(nextOption.label));
    uploadDocumentTypeOptions.push(formatSelectDocumentTypeOption(uploadDocumentTypes.OTHER));

    return uploadDocumentTypeOptions;
}

function formatSelectDocumentTypeOption(documentType) {
    return {
        label: getDocumentTypeLabel(documentType),
        value: documentType,
    };
}

function getPDFViewerMinHeight() {
    const hasVerticalScrollbar = document.body.clientHeight > window.innerHeight;

    return hasVerticalScrollbar ? '' : dimensions.section.main;
}

function getQuotationCompliancyInformationMessage(dossierCEE, operationSheets = [], quotationSignatureDate) {
    if (!dossierCEE || !dossierCEE?.quotation || !quotationHelper.isPremiumApplicableForQuotation(dossierCEE.quotation)) return '';

    const operations = dossierCEE?.quotation?.operations?.map((operation) => operationSheets.find((operationSheet) => operation.operationSheetName === operationSheet.name));

    let informationOperationSheetValidity = '';
    operations
        .filter((operation) => operationHelper.isEnergyRenovationOperation(operation))
        .forEach((operation) => {
            if (!!operation.obsoleteDate && dateUtils.isAfter(quotationSignatureDate, operation.obsoleteDate)) {
                informationOperationSheetValidity = informationOperationSheetValidity.concat(
                    `L'opération ${operation.formattedOperationSheetName} n'est plus valorisée par le dispositif des certificats d'économies d'énergies à compter du
                ${entitiesUtils.formatDate(operation.obsoleteDate)}`
                );
            }
        });
    return informationOperationSheetValidity;
}

function canShowCommentBlock(uploadDocument) {
    const allSitePhotos = [uploadDocumentTypes.SITE_PHOTO, uploadDocumentTypes.SITE_PHOTO_BEFORE_RENOVATION, uploadDocumentTypes.SITE_PHOTO_AFTER_RENOVATION];
    return allSitePhotos.includes(uploadDocument.documentType) && isValidValue(uploadDocument.comment);
}

function canShowHeatingPumpCoverageRateInput(documentType, dossierCEE) {
    if (documentType !== uploadDocumentTypes.SIZING_NOTE || isNullishOrEmpty(dossierCEE)) return false;

    const { operations = [] } = dossierCEE.quotation;

    return operations.some(({ operationSheetName }) => [operationSheetNames.BAR_TH_159V_A50_4, operationSheetNames.BAR_TH_159V_A50_4_CDP].includes(operationSheetName));
}

function formatHeatingPumpCoverageRateInputLabel({ quotation } = {}) {
    if (isNullishOrEmpty(quotation)) return LABEL_INPUT_HEATING_PUMP_COVERAGE_RATE;

    const heatingPumpCoverageRate = extractHeatingPumpCoverageRate({ quotation });

    return `${LABEL_INPUT_HEATING_PUMP_COVERAGE_RATE} - Ce taux doit être ≥ à ${heatingPumpCoverageRate}% (taux renseigné sur votre devis client)`;
}

function extractHeatingPumpCoverageRate({ quotation = {} } = {}) {
    const product = quotationHelper.extractMainProduct(quotation, [operationSheetNames.BAR_TH_159V_A50_4, operationSheetNames.BAR_TH_159V_A50_4_CDP]);
    if (isNonEmptyObject(product)) return product.heatingPumpCoverageRate;

    return 0;
}

const getAllOperationInNeedOfAnAttestation = ({ operations, dossierCEE }) => {
    return operations
        .reduce((acc, operation) => {
            if (isOperationOfRenovationGlobal(operation)) return getAllOperationInNeedOfAnAttestation({ operations: operation.renovationGlobal.operations, dossierCEE });
            const attestations = dossierCEE.mandatoryAttestations.filter((attestation) => attestation.operationUUID === operation.uuid);
            if (attestations.some((attestation) => !attestation.valid)) {
                acc.push(operation);
            }
            return acc;
        }, [])
        .flat();
};

function getAttestationLabelFromOperationUUID({ operations }, operationUUID) {
    const allOperationsInNeedOfAnAttestations = getAllOperationInNeedOfAnAttestation({ operations });
    const operation = allOperationsInNeedOfAnAttestations.find((operation) => operation.uuid === operationUUID);
    return operation?.operationName;
}

const getPublicLightingAttestationOperations = ({ attestations }) => {
    return attestations.filter(({ type, status }) => type === uploadDocumentTypes.ATTESTATION_PUBLIC_LIGHTNING && status !== 'SIGNED').map((attestation) => attestation);
};

function getListOfOperationNeedingAnAttestation({ operations, attestations }, dossierCEE, type) {
    const listOfOperationStrategies = {
        [uploadDocumentTypes.ATTESTATION_CHANGE_OF_PRODUCT]: () => getAllOperationInNeedOfAnAttestation({ operations, dossierCEE }),
        [uploadDocumentTypes.ATTESTATION_CHANGE_OF_SUBCONTRACTOR]: () => getAllOperationInNeedOfAnAttestation({ operations, dossierCEE }),
        [uploadDocumentTypes.ATTESTATION_PUBLIC_LIGHTNING]: () => getPublicLightingAttestationOperations({ operations, dossierCEE, attestations }),
    };
    const allAttestations = listOfOperationStrategies?.[type]?.({ operations, dossierCEE }) || [];
    return allAttestations.map((operation) => ({ label: operation.operationName, value: operation.uuid }));
}

const getNeedingSizingNotes = (dossierCEE) => {
    const operationNeedingSizingNotes = [
        operationSheetNames.BAR_TH_159V_A41_2_CDP,
        operationSheetNames.BAR_TH_159V_A41_2,
        operationSheetNames.BAR_TH_159V_A44_3_CDP,
        operationSheetNames.BAR_TH_159V_A44_3,
        operationSheetNames.BAR_TH_104V_A41_3_CDP,
        operationSheetNames.BAR_TH_104V_A41_3,
        operationSheetNames.BAR_TH_113V_A41_3,
        operationSheetNames.BAR_TH_113V_A41_3_CDP,
        operationSheetNames.BAR_TH_159V_A50_4_CDP,
        operationSheetNames.BAR_TH_159V_A50_4,
        operationSheetNames.BAR_TH_171V_A55_1,
        operationSheetNames.BAR_TH_171V_A55_1_CDP,
        operationSheetNames.BAR_TH_172V_A55_1,
        operationSheetNames.BAR_TH_172V_A55_1_CDP,
        operationSheetNames.BAR_TH_171V_A62_2,
        operationSheetNames.BAR_TH_171V_A62_2_CDP,
        operationSheetNames.BAR_TH_172V_A62_2,
        operationSheetNames.BAR_TH_172V_A62_2_CDP,
        operationSheetNames.BAT_TH_142V_A54_3,
    ];
    const isMpr = [
        representativeType.ADMINISTRATIVE_REPRESENTATIVE,
        representativeType.ADMINISTRATIVE_AND_FINANCIAL_REPRESENTATIVE,
        representativeType.FINANCIAL_REPRESENTATIVE,
        representativeType.DISPLAY_PRIME_WITHOUT_RENOLIB_AS_MANDATORY,
    ].includes(_.get(dossierCEE, 'quotation.renovationPremium.representativeType'));
    const needSizingNote = dossierCEE.mandatoryDocuments.find(({ type }) => type === uploadDocumentTypes.SIZING_NOTE);

    return needSizingNote
        ? dossierCEE?.quotation?.operations.filter(
              ({ operationSheetName, customerEnergySavingCertificatePremium }) => operationNeedingSizingNotes.includes(operationSheetName) && (isMpr || customerEnergySavingCertificatePremium > 0)
          )
        : [];
};

function getListOfOperationNeedingASizingNote({ operations }, dossierCEE) {
    return getNeedingSizingNotes(dossierCEE).map((operation) => {
        const operationLabel = operationHelper.getLabelValueFromInstallationDetailsToSpecify(operation, batTh142VA543AndProductsLabels.LABEL_DESTRATIFICATION_LOCAL_NAME);
        return {
            label: `Note de dimensionnement - ${operationLabel || operation.operationSheetName}`,
            value: operation.uuid,
        };
    });
}

function isAttestationType(formState) {
    return formState?.documentType && [uploadDocumentTypes.ATTESTATION_CHANGE_OF_PRODUCT, uploadDocumentTypes.ATTESTATION_CHANGE_OF_SUBCONTRACTOR].includes(formState.documentType);
}

function formatUploadDocumentForPreview(uploadDocument = {}) {
    const object = { document: { isDeletable: true, isDownloadable: false, isFileDeletable: uploadDocument.sitePhotos?.length > 1 } };

    if ([uploadDocumentTypes.SITE_PHOTO_BEFORE_RENOVATION, uploadDocumentTypes.SITE_PHOTO_AFTER_RENOVATION].includes(uploadDocument.documentType)) {
        const fileDownloadUrls = uploadDocument.sitePhotos?.map(({ fileDownloadUrl }) => ({ fileDownloadUrl }));
        Object.assign(object.document, { fileType: 'IMAGES', fileDownloadUrls });
    } else {
        Object.assign(object.document, { fileType: uploadDocument.fileType, fileDownloadUrl: uploadDocument.fileDownloadUrl });
    }

    return object;
}

function canDisplayDropZone({ documentType, files } = {}) {
    if (isNullishOrEmpty(documentType)) return false;

    return [uploadDocumentTypes.SITE_PHOTO_BEFORE_RENOVATION, uploadDocumentTypes.SITE_PHOTO_AFTER_RENOVATION].includes(documentType) ? isNullishOrEmpty(files) : true;
}

function isSizingNote(formState) {
    return formState?.documentType && [uploadDocumentTypes.SIZING_NOTE].includes(formState.documentType);
}

export default {
    formatUploadDocumentsForTable,
    getDocumentTypeLabel,
    extractFileNameAndFileType,
    isPostRenovationReport,
    isSignatureDateRequired,
    isPDFDocument,
    getUploadDocumentDossierCEEOptions,
    isDeletable,
    formatFileName,
    isQuotationEnergySavingCertificatePremiumValid,
    getUploadDocumentDeleteButtonMessage,
    canDisplayRenovationAddressAsText,
    getRenovationAddressAsText,
    getOperationsNames,
    getSelectDocumentTypeOptions,
    getPDFViewerMinHeight,
    formatSignatureDate,
    formatCreationDate,
    getQuotationCompliancyInformationMessage,
    canShowCommentBlock,
    extractQuotationFromUploadDocument,
    formatHeatingPumpCoverageRateInputLabel,
    canShowHeatingPumpCoverageRateInput,
    extractHeatingPumpCoverageRate,
    getListOfOperationNeedingAnAttestation,
    isAttestationType,
    formatUploadDocumentForPreview,
    getAttestationLabelFromOperationUUID,
    canDisplayDropZone,
    isCertificateOfCompliancy,
    isTaxNotice,
    isDpeOrAuditEnergetic,
    isSizingNote,
    getListOfOperationNeedingASizingNote,
};
