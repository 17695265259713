import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '@renolib/renolib-ui-kit';

import { LABEL_INPUT_COUNTRY } from '../../../../utils/form_labels';
import addressFormHelper from '../../../../utils/address-form-helper';

export default function CountrySelect({ defaultValue, disabled = false, error, onChange }) {
    function handleSelectCountry({ option: { value } }) {
        if (onChange) onChange({ name: 'country', value });
    }

    return (
        <Autocomplete
            fluid
            async={false}
            label={LABEL_INPUT_COUNTRY}
            options={addressFormHelper.getCountrySelectOptions()}
            defaultValue={defaultValue}
            disabled={disabled}
            error={error}
            onSelect={handleSelectCountry}
        />
    );
}
CountrySelect.propTypes = {
    defaultValue: PropTypes.node,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    onChange: PropTypes.func,
};
