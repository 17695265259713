import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BlockCard, Button, Field, Icons } from '@renolib/renolib-ui-kit';
import { useMutation } from '@tanstack/react-query';
import _ from 'lodash';
import styled from 'styled-components';

import formsActions from '../../../../store/actions/ui/form';
import customerPmService from '../../../../store/services/customer-pm.service';
import { isNonEmptyObject } from '../../../../utils';
import { customerPMActivityOptions } from '../../../../utils/select_options/customer-pm';
import Container from '../../../helpers/Container';
import Form from '../../../helpers/Form';
import { ErrorWithBadges } from '../../../helpers/MessageWithBadges';
import useForm from '../../../hooks/useForm';
import { customerPMActivitiesTranslations } from '../../../../utils/enums';

const StyledField = styled(Field)`
    &:has(input:disabled) {
        display: flex;
        align-items: center;
        gap: 8px;

        label {
            margin: 0;
            width: 130px;
        }
    }

    input:disabled {
        background: none;
        border: none;
        padding: 0;
        color: #000000;
        cursor: default;
        box-shadow: none;
        font-size: 14px;
        margin: 0;
        font-weight: 400;
    }
`;

export const SetCustomersPMInformation = ({ customerPM = {}, getCustomerPm, onSubmit, isManualMode }) => {
    const { siren } = useParams();
    const [canCreateError, setCanCreateError] = useState();

    const formatDefaultFormState = useCallback(
        (customerInformation) =>
            customerInformation
                ? {
                      ...customerInformation,
                  }
                : { name: '', commercialName: '', siren: null, siret: null, ape: null, vat: null, naf: null, activity: undefined },
        []
    );

    const { state, setErrors, reset, errors, handleChange } = useForm({ defaultFormState: formatDefaultFormState(customerPM) });
    const dispatch = useDispatch();

    const getErrors = (state) => {
        const obligatoryProperties = [
            { name: 'name', label: 'Dénomination' },
            { name: 'siren', label: 'Siren' },
            { name: 'siret', label: 'Siret' },
            { name: 'activity', label: "Secteur d'activité" },
            { name: 'ape', label: 'Forme juridique' },
            { name: 'vat', label: 'N° TVA' },
            { name: 'naf', label: 'Code NAF' },
        ];
        const errors = obligatoryProperties.reduce((acc, error) => {
            const value = _.get(state, error.name);

            if (!value) {
                acc = {
                    ...acc,
                    [`${error.name}`]: `${error.label} est obligatoire`,
                };
            } else {
                if (error.name === 'siren' && `${value}`.length !== 9) {
                    acc = { ...acc, [`${error.name}`]: `Le siren doit être égal a 9 caractères` };
                }
                if (error.name === 'siret' && `${value}`.length !== 14) {
                    acc = { ...acc, [`${error.name}`]: `Le siret doit être égal a 14 caractères` };
                }
            }
            return acc;
        }, {});
        setErrors(errors);
        return errors;
    };

    const handleSuccess = useCallback(() => {
        onSubmit(state);
    }, [state, onSubmit]);

    const handleError = (error) => {
        const message = _.get(error, 'response.data.message');
        setCanCreateError(message || 'Une erreur est survenue');
    };

    const onCanCreateFullCustomerPM = useMutation({
        mutationFn: customerPmService.canCreateACustomerPM,
        onMutate() {
            dispatch(formsActions.startRequesting());
        },
        onSuccess: handleSuccess,
        onError: handleError,
        onSettled() {
            dispatch(formsActions.stopRequesting());
        },
    });

    const onSubmitForm = () => {
        const errors = getErrors(state);

        if (isNonEmptyObject(errors)) return;
        if (isManualMode || siren === 'MANUAL') {
            onCanCreateFullCustomerPM.mutate({ data: { details: state } });
            return;
        }
        onSubmit(state);
    };

    useEffect(() => {
        if (siren === 'MANUAL') return; // so you know, if you move this elsewhere you will trigger an infinite render
        if (customerPM) {
            reset(formatDefaultFormState(customerPM));
            return;
        }
        getCustomerPm({ value: siren });
    }, [siren, dispatch, customerPM, formatDefaultFormState, reset, getCustomerPm]);

    if (!state) return <div />;

    return (
        <Container backButtonTitle='Retour à la recherche du siren' backUrl='/creer-client-professionnel/1'>
            <div className={'flex-row justify-content-center'}>
                <div className={'w-50'}>
                    <BlockCard title='FICHE CLIENT' icon={Icons.blockUser}>
                        {siren === 'MANUAL' && (
                            <div className={'background-color-emerald-greener-opacity color-primary justify-content-center flex-row justify-content-center p-2 mb-4 mt-2 border-radius-10'}>
                                <span className={'fs-12 text-color-primary italic bold'}>
                                    Pour vous facilitez la vie, vous pouvez entrer d'abord le numéro Siret de votre entreprise, pour faire apparaitre un lien vers votre avis de situation
                                    {state.siret && `${state.siret}`.length === 14 && (
                                        <a className={'ml-1'} href={`https://api-avis-situation-sirene.insee.fr/identification/pdf/${state?.siret}`} target={'_blank'} rel="noopener noreferrer">
                                            Avis de situation Insee
                                        </a>
                                    )}
                                </span>
                            </div>
                        )}
                        <Form.Group>
                            <StyledField
                                fluid
                                type='text'
                                label={'Dénomination :'}
                                name={'name'}
                                error={errors?.name}
                                onChange={handleChange}
                                disabled={!!customerPM.name}
                                defaultValue={state?.name}
                            />
                        </Form.Group>
                        <Form.Group>
                            <StyledField fluid type='number' label={'Siren :'} name={'siren'} error={errors?.siren} onChange={handleChange} disabled={!!customerPM.siren} defaultValue={state?.siren} />
                        </Form.Group>
                        <Form.Group>
                            <StyledField fluid type='number' label={'Siret :'} name={'siret'} error={errors?.siret} onChange={handleChange} disabled={!!customerPM.siret} defaultValue={state?.siret} />
                        </Form.Group>
                        <Form.Group>
                            <StyledField
                                fluid
                                type='text'
                                label={'Forme juridique :'}
                                name={'ape'}
                                error={errors?.ape}
                                onChange={handleChange}
                                disabled={_.get(customerPM, 'ape', false)}
                                defaultValue={state?.ape}
                            />
                        </Form.Group>
                        <Form.Group>
                            <StyledField
                                fluid
                                type='text'
                                label={'N° TVA :'}
                                name={'vat'}
                                error={errors?.vat}
                                onChange={handleChange}
                                disabled={_.get(customerPM, 'vat', false)}
                                defaultValue={state?.vat}
                            />
                        </Form.Group>
                        <Form.Group>
                            <StyledField
                                fluid
                                type='text'
                                label={'Code NAF :'}
                                name={'naf'}
                                error={errors?.naf}
                                onChange={handleChange}
                                disabled={_.get(customerPM, 'naf', false)}
                                defaultValue={state?.naf}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Field
                                fluid
                                type='select'
                                name='activity'
                                label="SECTEUR D'ACTIVITÉ"
                                defaultValue={state && state.activity ? customerPMActivitiesTranslations[state.activity] : ''}
                                placeholder={state && state.activity ? customerPMActivitiesTranslations[state.activity] : ''}
                                options={customerPMActivityOptions}
                                error={errors?.activity}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <div className={'flex-row justify-content-center pt-2'}>
                            <Button lightMode={'1'} disabled={!customerPM} onClick={onSubmitForm} className='color-white'>
                                Suivant
                            </Button>
                        </div>
                        {canCreateError && <ErrorWithBadges message={canCreateError} />}
                    </BlockCard>
                </div>
            </div>
        </Container>
    );
};
