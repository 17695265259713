import { batTh142VA543AndProductsLabels, batTh142VA543AndProductsValueTranslation } from './operations-sheets-labels/bat_th_142v_a54_3';
import entitiesUtils from './entities-utils';
import {
    energyRenovationWorks,
    functioningAlimentationTypesLabel,
    heatingPumpUsagesTranslations,
    heatPumpTypesLabel,
    luminaryPublicBuldLedTranslation,
    luminaryPublicLightingSubCategories,
    luminaryPublicLightingSubCategoriesTranslation,
    operationDetailTypesTranslations,
    photovoltaicPanelsSubCategories,
    photovoltaicPanelsSubCategoriesTranslation,
    productBackupEnergiesTranslations,
    productCategories,
    productStatuses,
    productSubCategoriesTranslations,
    productTypes,
    productUnitsLabelEnum,
    rackingProfilesLabels,
    reflectiveIsolantTranslations,
    ventilationTypesLabels,
    waterSavingSystemsClassesTranslations,
    woodHeatingTypesLabel,
} from './enums';
import { productCategoriesValues } from './enums';
import { LABEL_INPUT_CERTIFICATION, LABEL_INPUT_CERTIFICATION_ACERMI, LABEL_INPUT_JET_REGULATOR_CLASS, LABEL_INPUT_SHOWER_HEAD_CLASS, SOLAR_PRODUCT_CERTIFICATION_LABEL } from './form_labels';
import {
    LABEL_HEAT_EMITTER_TYPE,
    LABEL_REGULATION_SYSTEM_CENTRAL_SYSTEM_INCLUDES_API,
    LABEL_REGULATION_SYSTEM_CLASS,
    LABEL_REGULATION_SYSTEM_EQUIPMENT_CAN_HELP_DEROGATE_FROM_INSTRUCTIONS,
    LABEL_REGULATION_SYSTEM_INCLUDES_CENTRAL_SYSTEM,
    LABEL_REGULATION_SYSTEM_INCLUDES_PROBE,
    LABEL_REGULATION_SYSTEM_INCLUDES_REGULATOR,
    LABEL_REGULATION_SYSTEM_RUNS_OFFLINE,
    LABEL_REGULATION_SYSTEM_SETTINGS_ARE_LOCALLY_UPDATABLE,
    LABEL_REGULATION_SYSTEM_SIGNAL_ORIGIN,
} from './operation-constants';
import operationSheetNames from './operation-sheet-names';
import {
    LABEL_BOILER_CAPTORS_HYBRID_EXCLUSION,
    LABEL_BOILER_CAPTORS_SYSTEM_TYPE_EXCLUSION,
    LABEL_CHECKBOX_DOMTOM_WATER_HEATER_MANDATORY_AGREEMENT_ONE,
    LABEL_CHECKBOX_DOMTOM_WATER_HEATER_MANDATORY_AGREEMENT_TWO,
    LABEL_DATE_VALIDITE_AVIS_TECHNIQUE,
    LABEL_DOUBLE_FLOW_VENTILATION_CERTIFICATION,
    LABEL_GREEN_FLAME_LABEL_SEVEN,
    LABEL_HEATING_PUMP_USAGE,
    LABEL_INSULATING_COVER_IS_FLEXIBLE,
    LABEL_INSULATING_COVER_IS_MADE_OF_MINERAL_WOOL_BASED_INSULATOR,
    LABEL_INSULATING_COVER_IS_NOT_AN_INSULATING_SLEEVE,
    LABEL_ISOLANT_REFLECTIVE,
    LABEL_LA_POMPE_A_CHALEUR_EST_DE_TYPE_AIR_EAU,
    LABEL_LIGHT_IS_LED,
    LABEL_LUMINARY_TYPE,
    LABEL_NATURE_FLUID_CAPTORS,
    LABEL_OLD_OR_NEW_BUFFER_TANK_PRESENCE,
    LABEL_OLD_OR_NEW_SILO_OF_AT_LEAST_225_LITERS_PRESENCE,
    LABEL_RACKING_PROFILE,
    LABEL_RADIO_GROUP_DOUBLE_FLOW_BOX_IS_COLLECTIVE,
    LABEL_RADIO_GROUP_STATIC_EXCHANGER_IS_COLLECTIVE,
    LABEL_REPLACE_LIGHT_SOURCE,
    LABEL_SELECT_BACKUP_ENERGY,
    LABEL_TYPE_FONCTIONNEMENT,
    LABEL_TYPE_SYSTEME,
    LABEL_TYPE_VENTILATION,
    LABEL_USED_BIOMASS_IS_LIGNEOUS_BIOMASS_WITH_WOOD_BASE,
    SYSTEM_LINKED_WITH_CENTRAL_HEATING_TRANSMITTER,
} from './product-constants';
import productFormHelper from './product-form-helper';
import { OPTIONS_NATURE_FLUID_TRANSLATION } from './translation';

export const productCategoryOperations = {
    ISOLANT: [
        'BAR_EN_101V_A33_3',
        'BAR_EN_101V_A33_3_CDP',
        'BAR_EN_101V_A39_4',
        'BAR_EN_101V_A39_4_CDP',
        'BAR_EN_102V_A14_1',
        'BAR_EN_102V_A39_2',
        'BAR_EN_102V_A65_4',
        operationSheetNames.BAR_EN_103V_A36_4,
        operationSheetNames.BAR_EN_103V_A36_4_CDP,
        operationSheetNames.BAR_EN_103V_A39_5,
        operationSheetNames.BAR_EN_103V_A39_5_CDP,
        operationSheetNames.BAR_EN_103V_A64_6,
        operationSheetNames.BAR_EN_106V_A33_4,
        operationSheetNames.BAR_EN_106V_A64_5,
        'BAR_EN_107V_A20_3',
        'BAT_EN_101V_A33_3',
        'BAT_EN_102V_A27_2',
        'BAT_EN_103V_A33_3',
        'BAT_EN_106V_A33_2',
        'BAT_EN_108V_A19_1',
        operationSheetNames.BAR_EN_101V_A54_5,
        operationSheetNames.BAT_EN_101V_A64_4,
        operationSheetNames.BAT_EN_103V_A64_4,
        operationSheetNames.BAR_EN_101V_A64_6,
        operationSheetNames.BAT_EN_106V_A64_3,
        operationSheetNames.BAT_EN_102V_A64_3,
    ],
    POMPE_CHALEUR: [
        'BAR_TH_104V_A23_2',
        'BAR_TH_104V_A23_2_CDP',
        'BAR_TH_104V_A41_3',
        'BAR_TH_104V_A41_3_CDP',
        'BAR_TH_159V_A26_1',
        'BAR_TH_159V_A26_1_CDP',
        'BAR_TH_159V_A41_2',
        'BAR_TH_159V_A41_2_CDP',
        operationSheetNames.BAR_TH_129V_A27_3,
        operationSheetNames.BAR_TH_159V_A44_3,
        operationSheetNames.BAR_TH_159V_A44_3_CDP,
        operationSheetNames.BAR_TH_159V_A50_4,
        operationSheetNames.BAR_TH_159V_A50_4_CDP,
        operationSheetNames.BAR_TH_171V_A55_1,
        operationSheetNames.BAR_TH_171V_A55_1_CDP,
        operationSheetNames.BAR_TH_172V_A55_1,
        operationSheetNames.BAR_TH_172V_A55_1_CDP,
        operationSheetNames.BAR_TH_171V_A62_2,
        operationSheetNames.BAR_TH_171V_A62_2_CDP,
        operationSheetNames.BAR_TH_172V_A62_2,
        operationSheetNames.BAR_TH_172V_A62_2_CDP,
    ],
    TOITURE: [operationSheetNames.BAT_EN_109, operationSheetNames.BAR_EN_109],
    FENETRE: ['BAR_EN_104V_A14_1', operationSheetNames.BAR_EN_104V_A54_2],
    CHAUDIERE_INDIVIDUELLE: ['BAR_TH_106V_A23_2'],
    VENTILATION: [
        operationSheetNames.BAR_TH_127V_A36_3,
        operationSheetNames.BAR_TH_125V_A36_3,
        operationSheetNames.BAR_TH_125V_A40_4,
        operationSheetNames.BAR_TH_127V_A40_4,
        operationSheetNames.BAR_TH_125V_A54_5,
        operationSheetNames.BAR_TH_127V_A54_5,
        operationSheetNames.BAR_TH_127V_A58_6,
    ],
    CALORIFUGEAGE: [operationSheetNames.BAR_TH_160V_A27_1, operationSheetNames.BAR_TH_160V_A39_2, operationSheetNames.BAR_TH_160V_A54_4, operationSheetNames.BAT_TH_146],
    CHAUFFE_EAU_THERMODYNAMIQUE: [operationSheetNames.BAR_TH_148],
    CHAUFFE_EAU_SOLAIRE_INDIVIDUEL: [operationSheetNames.BAR_TH_101, operationSheetNames.BAR_TH_101V_A62_2],
    SYSTEME_SOLAIRE_COMBINE: [operationSheetNames.BAR_TH_143V_A25_1, operationSheetNames.BAR_TH_143V_A25_1_CDP, operationSheetNames.BAR_TH_143V_A51_5, operationSheetNames.BAR_TH_143V_A51_5_CDP],
    APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS: [
        operationSheetNames.BAR_TH_112V_A35_2,
        operationSheetNames.BAR_TH_112V_A35_2_CDP,
        operationSheetNames.BAR_TH_112V_A46_3,
        operationSheetNames.BAR_TH_112V_A46_3_CDP,
    ],
    CHAUDIERE_BIOMASSE_INDIVIDUELLE: [operationSheetNames.BAR_TH_113V_A41_3, operationSheetNames.BAR_TH_113V_A41_3_CDP],
    CHAUFFE_EAU_SOLAIRE: [operationSheetNames.BAR_TH_124V_A35_3],
    ECLAIRAGE_PUBLIC: [operationSheetNames.RES_EC_104],
    PHOTOVOLTAIC_PANELS: [energyRenovationWorks.PHOTOVOLTAIC_PANELS],
    THERMOSTATIC_VALVE: [operationSheetNames.BAT_TH_104V_A32_2],
    WATER_SAVING_SYSTEMS: [operationSheetNames.BAT_EQ_133V_A17_1],
    HOUSSE_ISOLANTE: [operationSheetNames.BAR_TH_161V_A54_2],
    PORTE: [operationSheetNames.PORTE],
    REGULATION_SYSTEM: [operationSheetNames.BAR_TH_173V_A56_1, operationSheetNames.BAR_TH_173V_A56_1_CDP],
    SYSTEME_DE_DESTRATIFICATION_D_AIR: [operationSheetNames.BAT_TH_142V_A54_3],
    AIR_CONDITIONING_INTERMITTENCE_PROGRAMMER: [operationSheetNames.BAT_TH_122V_A22_1],
};

export const isAProductCategoryWithCertificationCSTBat = (productCategory) =>
    [productCategoriesValues.SYSTEME_SOLAIRE_COMBINE, productCategoriesValues.CHAUFFE_EAU_SOLAIRE_INDIVIDUEL].includes(productCategory);

function getMainProduct(products = [], productUUID) {
    return productUUID
        ? products.find((quotationProduct) => quotationProduct?.product?.type === productTypes.MAIN_PRODUCT && quotationProduct?.uuid === productUUID)
        : products.find((quotationProduct) => quotationProduct?.product?.type === productTypes.MAIN_PRODUCT);
}

function filterProducts(products = [], type) {
    return products.filter(({ product }) => product?.type === type);
}

function filterMainProducts(products) {
    return filterProducts(products, productTypes.MAIN_PRODUCT);
}

function filterOtherProducts(products) {
    return filterProducts(products, productTypes.OTHER_PRODUCT);
}

function filterNonEnergyRenovationOperationMainProducts(products) {
    return filterProducts(products, productTypes.NON_ENERGY_RENOVATION_OPERATION_MAIN_PRODUCT);
}

function getProductCategory(operationSheetName) {
    const foundCategories = Object.keys(productCategoryOperations).filter((category) => productCategoryOperations[category].includes(operationSheetName));

    return foundCategories[0];
}

function getProductBlockTitle(operationSheetName, { product = {} }) {
    const text = 'Produit';

    if (product?.subCategory) return text.concat(` : ${productSubCategoriesTranslations[product.subCategory]}`);

    return text.concat(operationSheetName ? ` : ${getProductCategoryLabel(operationSheetName)}` : '');
}

const getUnitLabel = (unit) => {
    return productUnitsLabelEnum[unit];
};

function getProductCategoryLabel(operationSheetName) {
    return productCategories[getProductCategory(operationSheetName)];
}

function areProductPropertiesValid(product, productType) {
    const requiredProperties = {
        [productTypes.MAIN_PRODUCT]: ['title', 'brand', 'reference'],
        [productTypes.OTHER_PRODUCT]: ['title'],
    };
    const providedProperties = Object.keys(product);

    return requiredProperties[productType].every((property) => providedProperties.includes(property));
}

function isAMainProduct(product) {
    return product?.type === productTypes.MAIN_PRODUCT;
}

function getProductDetailToSpecifyValue(productDetailToSpecify, operation, operationSheetName = '') {
    const strategies = {
        [LABEL_TYPE_VENTILATION]: getValueLabel,
        [LABEL_TYPE_FONCTIONNEMENT]: getValueLabel,
        [LABEL_LA_POMPE_A_CHALEUR_EST_DE_TYPE_AIR_EAU]: getValueLabel,
        [LABEL_DATE_VALIDITE_AVIS_TECHNIQUE]: formatValueToDate,
        [LABEL_NATURE_FLUID_CAPTORS]: getNaturalFluidValueTranslation,
        [LABEL_RACKING_PROFILE]: getRackingProfileValueLabel,
        [LABEL_GREEN_FLAME_LABEL_SEVEN]: getValueLabel,
        [SYSTEM_LINKED_WITH_CENTRAL_HEATING_TRANSMITTER]: getValueLabel,
        [LABEL_USED_BIOMASS_IS_LIGNEOUS_BIOMASS_WITH_WOOD_BASE]: getValueLabel,
        [LABEL_OLD_OR_NEW_SILO_OF_AT_LEAST_225_LITERS_PRESENCE]: getValueLabel,
        [LABEL_OLD_OR_NEW_BUFFER_TANK_PRESENCE]: getValueLabel,
        [LABEL_RADIO_GROUP_DOUBLE_FLOW_BOX_IS_COLLECTIVE]: getValueLabel,
        [LABEL_RADIO_GROUP_STATIC_EXCHANGER_IS_COLLECTIVE]: getValueLabel,
        [LABEL_SELECT_BACKUP_ENERGY]: getValueLabel,
        [LABEL_CHECKBOX_DOMTOM_WATER_HEATER_MANDATORY_AGREEMENT_ONE]: getValueLabel,
        [LABEL_CHECKBOX_DOMTOM_WATER_HEATER_MANDATORY_AGREEMENT_TWO]: getValueLabel,
        [LABEL_LUMINARY_TYPE]: getValueLabel,
        [LABEL_LIGHT_IS_LED]: getValueLabel,
        [LABEL_REPLACE_LIGHT_SOURCE]: getValueLabel,
        [LABEL_HEATING_PUMP_USAGE]: getValueLabel,
        [LABEL_INPUT_SHOWER_HEAD_CLASS]: getValueLabel,
        [LABEL_INPUT_JET_REGULATOR_CLASS]: getValueLabel,
        [LABEL_INSULATING_COVER_IS_FLEXIBLE]: getValueLabel,
        [LABEL_INSULATING_COVER_IS_MADE_OF_MINERAL_WOOL_BASED_INSULATOR]: getValueLabel,
        [LABEL_INSULATING_COVER_IS_NOT_AN_INSULATING_SLEEVE]: getValueLabel,
        [LABEL_REGULATION_SYSTEM_CLASS]: getValueLabel,
        [LABEL_REGULATION_SYSTEM_INCLUDES_CENTRAL_SYSTEM]: getValueLabel,
        [LABEL_REGULATION_SYSTEM_INCLUDES_PROBE]: getValueLabel,
        [LABEL_REGULATION_SYSTEM_INCLUDES_REGULATOR]: getValueLabel,
        [LABEL_REGULATION_SYSTEM_SETTINGS_ARE_LOCALLY_UPDATABLE]: getValueLabel,
        [LABEL_REGULATION_SYSTEM_EQUIPMENT_CAN_HELP_DEROGATE_FROM_INSTRUCTIONS]: getValueLabel,
        [LABEL_REGULATION_SYSTEM_RUNS_OFFLINE]: getValueLabel,
        [LABEL_REGULATION_SYSTEM_CENTRAL_SYSTEM_INCLUDES_API]: getValueLabel,
        [LABEL_REGULATION_SYSTEM_SIGNAL_ORIGIN]: getValueLabel,
        [LABEL_HEAT_EMITTER_TYPE]: getValueLabel,
        [LABEL_ISOLANT_REFLECTIVE]: getValueLabelIsReflectiveInsulation,
        [LABEL_BOILER_CAPTORS_HYBRID_EXCLUSION]: getValueLabel,
        [LABEL_BOILER_CAPTORS_SYSTEM_TYPE_EXCLUSION]: getValueLabel,
        [LABEL_TYPE_SYSTEME]: getValueLabel,
        [batTh142VA543AndProductsLabels.LABEL_DESTRATIFICATION_DISPOSITIF_MELANGE_AIR]: getValueLabel,
        [LABEL_DOUBLE_FLOW_VENTILATION_CERTIFICATION]: getValueLabel,
    };

    return Object.keys(strategies).includes(productDetailToSpecify.label)
        ? strategies[productDetailToSpecify.label](productDetailToSpecify.value, operation?.operationSheetName || operationSheetName)
        : productDetailToSpecify.value;
}

function getProductExclusionDetailToSpecifyValue(productExclusionDetailToSpecify) {
    const strategies = {
        [LABEL_BOILER_CAPTORS_HYBRID_EXCLUSION]: getValueLabel,
        [LABEL_BOILER_CAPTORS_SYSTEM_TYPE_EXCLUSION]: getValueLabel,
    };
    return Object.keys(strategies).includes(productExclusionDetailToSpecify.label)
        ? strategies[productExclusionDetailToSpecify.label](productExclusionDetailToSpecify.value)
        : productExclusionDetailToSpecify.value;
}

function getValueLabel(productDetailToSpecifyValue) {
    const possibleValues = {
        ...ventilationTypesLabels,
        ...heatPumpTypesLabel,
        ...functioningAlimentationTypesLabel,
        ...woodHeatingTypesLabel,
        ...productBackupEnergiesTranslations,
        ...luminaryPublicLightingSubCategoriesTranslation,
        ...luminaryPublicBuldLedTranslation,
        ...heatingPumpUsagesTranslations,
        ...waterSavingSystemsClassesTranslations,
        ...operationDetailTypesTranslations,
        ...reflectiveIsolantTranslations,
        ...batTh142VA543AndProductsValueTranslation,
        true: 'Oui',
        false: 'Non',
    };

    return possibleValues[productDetailToSpecifyValue];
}

function getValueLabelIsReflectiveInsulation(isReflectiveValue, operationSheetName) {
    const newOperations = [
        operationSheetNames.BAR_EN_101V_A64_6,
        operationSheetNames.BAR_EN_102V_A65_4,
        operationSheetNames.BAR_EN_103V_A64_6,
        operationSheetNames.BAR_EN_106V_A64_5,
        operationSheetNames.BAT_EN_101V_A64_4,
        operationSheetNames.BAT_EN_103V_A64_4,
        operationSheetNames.BAT_EN_106V_A64_3,
    ];
    if (newOperations.includes(operationSheetName)) {
        return reflectiveIsolantTranslations[isReflectiveValue];
    }
    return isReflectiveValue === 'IS_REFLECTIVE' ? 'Isolant réfléchissant (NF EN 16012+A1 ou NF EN ISO 22097)' : reflectiveIsolantTranslations.IS_NOT_REFLECTIVE;
}

function getRackingProfileValueLabel(rackingProfileValue) {
    return rackingProfilesLabels[rackingProfileValue];
}

function getNaturalFluidValueTranslation(naturalFluidLabel) {
    return Object.keys(OPTIONS_NATURE_FLUID_TRANSLATION).includes(naturalFluidLabel) ? OPTIONS_NATURE_FLUID_TRANSLATION[naturalFluidLabel] : '';
}

function formatValueToDate(productDetailToSpecifyValue) {
    return entitiesUtils.formatDate(productDetailToSpecifyValue);
}

function productExclusionDetailsToSpecifyExist(detailsPerOperation) {
    return detailsPerOperation?.details?.productExclusionDetailsToSpecify?.length > 0;
}

function productDetailsToSpecifyExist(detailsPerOperation) {
    return detailsPerOperation?.details?.productDetailsToSpecify?.length > 0;
}

function productFilesExist(productFiles) {
    return productFiles.length > 0;
}

function getCertificationWording(product) {
    if (isAProductCategoryWithCertificationCSTBat(product.category)) return SOLAR_PRODUCT_CERTIFICATION_LABEL;
    return [productCategoriesValues.ISOLANT, productCategoriesValues.CALORIFUGEAGE].includes(product.category) ? LABEL_INPUT_CERTIFICATION_ACERMI : LABEL_INPUT_CERTIFICATION;
}

function getProductOrFormatToFormData(product) {
    const formData = new FormData();

    if (product?.file) {
        formData.append('file', product.file);
        delete product.file;
    }

    if (product?.technicalSheetFile) {
        formData.append('technicalSheetFile', product.technicalSheetFile);
        delete product.technicalSheetFile;
    }

    formData.append('data', JSON.stringify(product));

    return formData;
}

const isProductConfirmed = (product) => product?.status === productStatuses.CONFIRMED;

const shouldDisplaySevenGreenLabelEquivalence = ({ operationSheetName, detailsPerOperation }) => {
    if (
        [
            operationSheetNames.BAR_TH_112V_A35_2,
            operationSheetNames.BAR_TH_112V_A35_2_CDP,
            operationSheetNames.BAR_TH_112V_A46_3_CDP,
            operationSheetNames.BAR_TH_112V_A46_3,
            operationSheetNames.BAR_TH_113V_A41_3_CDP,
            operationSheetNames.BAR_TH_113V_A41_3,
        ].includes(operationSheetName)
    ) {
        const compliancy = detailsPerOperation[operationSheetName]?.compliancyByInstallationTypes?.NONE?.compliant;
        const productDetailsToSpecify = detailsPerOperation[operationSheetName]?.details?.productDetailsToSpecify;
        const greenLabelSeven = productDetailsToSpecify?.find(({ label }) => label === LABEL_GREEN_FLAME_LABEL_SEVEN);

        return !greenLabelSeven?.value && compliancy;
    }
    return false;
};

export const lampSubCategoriesOptions = () => Object.keys(luminaryPublicLightingSubCategories).map((key) => ({ label: luminaryPublicLightingSubCategoriesTranslation[key], value: key }));

export const photovoltaicPanelsCategoriesOptions = () => Object.keys(photovoltaicPanelsSubCategories).map((key) => ({ label: photovoltaicPanelsSubCategoriesTranslation[key], value: key }));

function isPhotovoltaicPanelsCategory(product) {
    return product?.category && product.category === productCategoriesValues.PHOTOVOLTAIC_PANELS;
}

function getSelectFunctioningTypeOptionsForBarTh112() {
    return Object.keys(woodHeatingTypesLabel).map((key) => ({
        value: key,
        label: woodHeatingTypesLabel[key],
    }));
}

function getSelectFunctioningTypeOptionsForBarTh113() {
    return Object.keys(functioningAlimentationTypesLabel).map((key) => ({
        value: key,
        label: functioningAlimentationTypesLabel[key],
    }));
}
function formattedLabel(label) {
    return `${label} (${productFormHelper.getEnergyEfficiencyUnit(label)})`;
}

function formatObjectLabelsToAnArrayOfValueLabelPair(objectLabels) {
    return Object.keys(objectLabels).map((key) => ({
        value: key,
        label: objectLabels[key],
    }));
}

export default {
    filterMainProducts,
    filterOtherProducts,
    filterNonEnergyRenovationOperationMainProducts,
    getProductCategory,
    getProductBlockTitle,
    getValueLabel,
    getUnitLabel,
    getProductOrFormatToFormData,
    areProductPropertiesValid,
    isAMainProduct,
    getProductDetailToSpecifyValue,
    getProductExclusionDetailToSpecifyValue,
    productDetailsToSpecifyExist,
    productExclusionDetailsToSpecifyExist,
    getCertificationWording,
    getMainProduct,
    isProductConfirmed,
    productFilesExist,
    shouldDisplaySevenGreenLabelEquivalence,
    photovoltaicPanelsCategoriesOptions,
    isPhotovoltaicPanelsCategory,
    getSelectFunctioningTypeOptionsForBarTh112,
    formattedLabel,
    getSelectFunctioningTypeOptionsForBarTh113,
    formatObjectLabelsToAnArrayOfValueLabelPair,
};
