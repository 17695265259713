import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Field } from '@renolib/renolib-ui-kit';

import { LABEL_INPUT_CITY, LABEL_INPUT_POSTAL_CODE, LABEL_INPUT_STREET_NAME, LABEL_INPUT_STREET_NUMBER } from '../../../../utils/form_labels';
import { isValidValue } from '../../../../utils';
import { addressPropType } from '../../../../utils/prop-types-schemas';
import { ADDRESS_FORM_TEST_ID } from '../../../../tests/utils/tests-ids';

import Form from '../../../helpers/Form';
import CountrySelect from './CountrySelect';
import addressFormHelper from '../../../../utils/address-form-helper';

export default function AddressForm({ formState = {}, formErrors = {}, editable = true, disableFilledFields = false, onChange }) {
    function isFieldDisabled(propertyName) {
        if (!editable) return true;

        return disableFilledFields ? isValidValue(formState[propertyName]) : false;
    }

    return (
        <div data-testid={ADDRESS_FORM_TEST_ID}>
            <Form.Group>
                <Row>
                    <Col md='3'>
                        <Field
                            fluid
                            type='text'
                            name='streetNumber'
                            label={LABEL_INPUT_STREET_NUMBER}
                            defaultValue={formState.streetNumber}
                            error={formErrors.streetNumber}
                            disabled={isFieldDisabled('streetNumber')}
                            onChange={(value) => {
                                return onChange({ ...value, value: addressFormHelper.cleanStr(value.value) });
                            }}
                        />
                    </Col>
                    <Col md='5'>
                        <Field
                            fluid
                            type='text'
                            name='streetName'
                            label={LABEL_INPUT_STREET_NAME}
                            defaultValue={formState.streetName}
                            error={formErrors.streetName}
                            disabled={isFieldDisabled('streetName')}
                            onChange={(value) => {
                                return onChange({ ...value, value: addressFormHelper.cleanStr(value.value) });
                            }}
                        />
                    </Col>
                    <Col md='4'>
                        <Field
                            fluid
                            type='text'
                            name='postalCode'
                            label={LABEL_INPUT_POSTAL_CODE}
                            defaultValue={formState.postalCode}
                            error={formErrors.postalCode}
                            disabled={isFieldDisabled('postalCode')}
                            onChange={onChange}
                        />
                    </Col>
                </Row>
            </Form.Group>
            <Form.Group>
                <Row>
                    <Col>
                        <Field fluid type='text' name='city' label={LABEL_INPUT_CITY} defaultValue={formState.city} error={formErrors.city} disabled={isFieldDisabled('city')} onChange={onChange} />
                    </Col>
                    <Col>
                        <CountrySelect defaultValue={formState.country} disabled={isFieldDisabled('country')} error={formErrors.country} onChange={onChange} />
                    </Col>
                </Row>
            </Form.Group>
        </div>
    );
}
AddressForm.propTypes = {
    formState: addressPropType,
    formErrors: PropTypes.shape({}),
    editable: PropTypes.bool,
    disableFilledFields: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};
