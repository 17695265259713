import { useCallback, useMemo, useState } from 'react';
import { debounce } from 'lodash';

import useGetOrganizationInseeQueryThroughSiren from './useGetOrganizationInseeQueryThroughSiren';

export const useGetCustomerPMThroughInsee = ({ options } = {}) => {
    const [searchedSiren, setSearchSiren] = useState();
    const [selectedCustomer, setSelectedCustomer] = useState();
    const [localError, setLocalError] = useState();
    const [inseeRequestOptions, setInseeRequestOptions] = useState(options);

    const {
        inseeCustomer: customerPmAutocompleteResult,
        isLoading: isSearchLoading,
        isSuccess,
        isError,
        error: searchError,
        isAnErrorTriggeringManualMode,
    } = useGetOrganizationInseeQueryThroughSiren(searchedSiren, inseeRequestOptions);

    const handleSearchCustomerPmSiren = useCallback(async ({ value }) => {
        if (!value) return;
        const formattedValue = value.replace(/ /g, '');
        if (formattedValue.length !== 9) {
            setLocalError('Le siren doit faire 9 caractères');
            return;
        }
        setSearchSiren(formattedValue);
        setLocalError();
    }, []);

    const onDebounceSearchCustomerPm = useMemo(() => debounce((values) => handleSearchCustomerPmSiren(values), 500), [handleSearchCustomerPmSiren]);

    return {
        onDebounceSearchCustomerPm,
        isSearchLoading,
        customerPmAutocompleteResult,
        searchError: searchError || localError,
        selectedCustomer,
        isAnErrorTriggeringManualMode,
        isSuccess,
        isError,
        setSelectedCustomer,
        setInseeRequestOptions,
    };
};
