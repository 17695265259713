import InvalidAddressForm from '../../../helpers/InvalidAddressForm';
import InvalidMessage from '../../../helpers/InvalidMessage';
import { INVALID_ADDRESS } from '../../../../utils/messages';
import AddressForm from '../forms/AddressForm';
import React from 'react';

export const DisplayInvalidAddressForm = ({ validAddress, addressFormProps, displayTextConfig = { displayText: true } }) => {
    return (
        <InvalidAddressForm>
            {!validAddress && displayTextConfig.displayText && <InvalidMessage>{INVALID_ADDRESS}</InvalidMessage>}
            <AddressForm {...addressFormProps} />
        </InvalidAddressForm>
    );
};
