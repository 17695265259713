import { authHeaders, endpointV2 } from '../../api';

const autocomplete = async (query) =>
    endpointV2.get('/services/remote-organization-information/autocomplete', {
        headers: authHeaders(),
        params: {
            query,
        },
    });

const getOrganizationInformation = async (companyId) =>
    endpointV2.get('/services/remote-organization-information/organization-information', {
        headers: authHeaders(),
        params: {
            companyId,
        },
    });

const getOrganizationInformationForCustomerPm = async (companyId) =>
    endpointV2.get('/services/remote-organization-information/organization-information_pm', {
        headers: authHeaders(),
        params: {
            companyId,
        },
    });

async function getCustomerPMAddresses(siret) {
    return endpointV2.get('/services/remote-organization-information/organization-information_pm_addresses', {
        headers: authHeaders(),
        params: { siret },
    });
}

const getOrganizationInseeInformationThroughSiren = async ({ siren, options }) =>
    endpointV2.get('/services/remote-organization-information/get-organizations-insee-information-through-siren', {
        headers: authHeaders(),
        params: {
            siren,
            options,
        },
    });

export default {
    autocomplete,
    getOrganizationInformation,
    getOrganizationInformationForCustomerPm,
    getCustomerPMAddresses,
    getOrganizationInseeInformationThroughSiren,
};
