import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../helpers/Modal';
import ContentSectorsActivity from './ContentSectorsActivity';

export default function SectorsActivityModal({ show, onHide }) {
    return (
        <Modal title='Les secteurs d’activité Tertiaire' show={show} onHide={onHide}>
            <Modal.Body>
                <ContentSectorsActivity />
            </Modal.Body>
        </Modal>
    );
}
SectorsActivityModal.propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func,
};
