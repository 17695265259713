import _ from 'lodash';
import { v1 as uuidV1 } from 'uuid';

import { renovationAddressBuildingSectors } from '../../../../utils/enums';

export const adaptRenovationAddressFromInseeToApiNecessite = (address) => {
    return {
        addressName: address.addressName,
        buildingSector: address?.buildingSector,
        siret: address?.siret,
        cadastralParcelNumber: address?.cadastralParcelNumber,
        streetNumber: address?.streetNumber,
        streetName: address?.streetName,
        city: address?.city,
        activityArea: address?.activityArea,
        country: address?.country,
        postalCode: address?.postalCode,
        housingType: address?.housingType,
        apartmentNumber: address?.apartmentNumber,
        isManualAddress: address?.isCustom,
    };
};

export const adaptAddressFromInseeToApiNecessity = (address) => {
    return {
        addressName: address?.addressName,
        buildingSector: address?.buildingSector,
        cadastralParcelNumber: address?.cadastralParcelNumber,
        streetNumber: address?.streetNumber,
        streetName: address.streetName,
        city: address.city,
        activityArea: address?.activityArea,
        country: address.country,
        postalCode: address.postalCode,
        isMainAddress: !!address.etablissementSiege,
        siret: address?.siret ? Number(address?.siret) : address?.siret,
    };
};

export const formatCustomerForApi = (customers, options) => {
    const { details, addresses, contacts } = customers;
    return {
        details,
        renovationAddresses: addresses.filter(({ addAsRenovationAddress }) => addAsRenovationAddress).map(adaptRenovationAddressFromInseeToApiNecessite),
        addresses: addresses && addresses.filter(({ buildingSector }) => buildingSector === renovationAddressBuildingSectors.TERTIARY).map(adaptAddressFromInseeToApiNecessity),
        contacts,
        isManualMode: options.isManualMode,
    };
};

export const fromCustomerInseeToCustomerFrontForm = (customer) => {
    return {
        details: {
            name: customer.name,
            commercialName: customer?.name,
            siren: customer?.siren,
            siret: customer?.siret,
            isMainEtablissementClosed: !_.get(customer, 'etablissements', []).some(({ etablissementSiege }) => etablissementSiege),
            isEverythingClosed: _.get(customer, 'etablissements', []).length === 0,
            ape: _.get(customer, 'mainEtablissement.ape'),
            vat: _.get(customer, 'mainEtablissement.vat'),
            naf: _.get(customer, 'mainEtablissement.naf'),
            activity: undefined,
        },
        etablissements: customer.etablissements.map((etablissement) => ({
            ...etablissement,
            siren: etablissement.siren,
            siret: etablissement.siret,
            etablissementSiege: etablissement?.etablissementSiege,
            cadastralParcelNumber: etablissement?.cadastralParcelNumber,
            buildingSector: etablissement.buildingSector,
            activityArea: etablissement.activityArea,
            addAsRenovationAddress: false,
            isCustom: false,
            uuid: uuidV1(),
            addressName: etablissement.etablissementSiege ? _.get(etablissement, `uniteLegale.denominationUniteLegale`) : etablissement?.addressName,
        })),
        contacts: [],
        renovationAddresses: [],
    };
};
