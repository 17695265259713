import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import dossierCEEActions from '../../../store/actions/dossier-cee.actions';
import operationSheetActions from '../../../store/actions/operation-sheet.actions';
import uploadDocumentActionsService from '../../../store/actions/upload-document.actions';
import { isNonEmptyObject } from '../../../utils';
import accountHelper from '../../../utils/account-helper';
import dossierCeeHelper from '../../../utils/dossier-cee-helper';
import { applicationPages } from '../../../utils/enums';
import { uploadDocumentTypes } from '../../../utils/enums';
import history from '../../../utils/history';
import operationHelper from '../../../utils/operation-helper';
import { batTh142VA543AndProductsLabels } from '../../../utils/operations-sheets-labels/bat_th_142v_a54_3';
import CreateUploadDocumentForm from '../../core/upload_documents/forms/CreateUploadDocumentForm';
import { getOccupantsOptions } from '../../core/upload_documents/forms/getOccupantsOptionsFromDossierCEE';
import Container from '../../helpers/Container';
import { useUploadDocuments } from '../../hooks/useUploadDocuments';

export const useInitUploadDocumentData = ({ initDossierCEE, currentApplicationPages }) => {
    const { documentType, currentAttestionIndex, uuid } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const occupantUUID = useMemo(() => queryParams.get('occupant'), [queryParams]);
    const operationUUID = useMemo(() => queryParams.get('operationUUID'), [queryParams]);

    const { dossierCEE: dossierCEEFromSelector } = useSelector((state) => state.dossiersCEEState);
    const [dossierCEE, setDossierCEE] = useState(initDossierCEE || dossierCEEFromSelector);

    useEffect(() => {
        setDossierCEE(dossierCEEFromSelector || initDossierCEE);
    }, [initDossierCEE, dossierCEEFromSelector]);

    const dispatch = useDispatch();

    const [defaultFormState, setDefaultFormState] = useState();

    const getAttestationConfiguration = useCallback(
        (currentAttestionIndex) => {
            if (!dossierCEE) return {};
            const currentAttestation = dossierCEE?.quotation?.attestations.filter(({ type }) => type === documentType)[currentAttestionIndex];
            if (!currentAttestation) return {};
            return {
                attestationOperationName: currentAttestation.operationName,
                operationUUID: currentAttestation.uuid,
            };
        },
        [documentType, dossierCEE]
    );

    const sizingNoteConfiguration = useCallback(() => {
        const operation = dossierCEE?.quotation?.operations?.find(({ uuid }) => uuid === operationUUID);
        if (!operation) return {};
        const sizingNoteOperationName =
            operationHelper.getLabelValueFromInstallationDetailsToSpecify(operation, batTh142VA543AndProductsLabels.LABEL_DESTRATIFICATION_LOCAL_NAME) || operation.operationSheetName;

        return {
            sizingNoteOperationName,
            operationUUID: operation.uuid,
        };
    }, [operationUUID, dossierCEE]);

    const updateDefaultFormState = useCallback(
        (currentAttestionIndex) => {
            if (!dossierCEE) return;
            const strategies = {
                [`${uploadDocumentTypes.ATTESTATION_CHANGE_OF_PRODUCT}`]: (currentAttestionIndex) => getAttestationConfiguration(currentAttestionIndex),
                [`${uploadDocumentTypes.ATTESTATION_CHANGE_OF_SUBCONTRACTOR}`]: (currentAttestionIndex) => getAttestationConfiguration(currentAttestionIndex),
                [`${uploadDocumentTypes.TAX_NOTICE}`]: () => {
                    const occupants = getOccupantsOptions({ dossierCEE });
                    const occupant = occupants.find(({ value }) => value === occupantUUID);
                    return { occupant: occupant.value };
                },
                [`${uploadDocumentTypes.SIZING_NOTE}`]: () => sizingNoteConfiguration(),
            };

            const defaultState = {
                documentType: documentType,
                dossierCEE: dossierCEE,
                dossierCEEId: `${dossierCEE._id}`,
                quotation: dossierCEE.quotation,
                quotationId: `${dossierCEE.quotation._id}`,
            };
            if (strategies[documentType]) {
                Object.assign(defaultState, strategies[documentType](currentAttestionIndex));
            }
            setDefaultFormState(defaultState);
        },
        [documentType, dossierCEE, setDefaultFormState, getAttestationConfiguration, occupantUUID, sizingNoteConfiguration]
    );

    useEffect(() => {
        if (!documentType || !dossierCEE) return;
        updateDefaultFormState(currentAttestionIndex);
    }, [currentAttestionIndex, updateDefaultFormState, documentType, dossierCEE]);

    useEffect(() => {
        if (dossierCEE) return;
        else if (uuid) dispatch(dossierCEEActions.getDossierCEE({ dossierCEEUUID: uuid }));
    }, [uuid, dispatch, dossierCEE]);

    return { defaultFormState, dossierCEE };
};

export const CreateUploadDocument = ({
    dossierCEE,
    redirectRoute = `/documents`,
    uploadDocumentActions = uploadDocumentActionsService,
    getDossierCEE,
    currentApplicationPages,
    onCreated,
    disabledSelectDossierCEE,
}) => {
    const dispatch = useDispatch();
    const { account = {} } = useSelector((state) => state.auth);
    const { defaultFormState = {}, ...restOfInitData } = useInitUploadDocumentData({ initDossierCEE: dossierCEE, getDossierCEE });
    const alerts = useMemo(() => {
        return dossierCeeHelper.extractAlertsFromDossier(defaultFormState.dossierCEE, defaultFormState.documentType);
    }, [defaultFormState.dossierCEE, defaultFormState.documentType]);

    const { operationSheets } = useSelector((state) => state.operationSheetsState);

    const useUploadDocumentsProperties = {
        onDocumentCreated,
        defaultFormState: defaultFormState,
        uploadDocumentActions,
    };
    if (accountHelper.isRenolibControllerRole(account)) {
        useUploadDocumentsProperties.dossierCEE = isNonEmptyObject(restOfInitData.dossierCEE) ? restOfInitData.dossierCEE : dossierCEE;
    }
    const { checkIfDocumentExist, state, errors, updateState, handleChange, validateUploadDocumentForm, message, handleResetDocumentCheckMessage } = useUploadDocuments(useUploadDocumentsProperties);

    function onDocumentCreated() {
        if (onCreated) return onCreated();
        history.push('/documents');
    }

    function handleSave(evt) {
        if (evt) evt.preventDefault();
        validateUploadDocumentForm({ applicationPage: currentApplicationPages || applicationPages.UPLOAD_DOCUMENT });
    }

    useEffect(() => {
        dispatch(operationSheetActions.getOperationSheets({ withObsoleteDateFilter: false }));

        return () => {
            dispatch(uploadDocumentActions.resetDocumentCheckMessage());
        };
    }, [dispatch, uploadDocumentActions]);

    return (
        <>
            <Container backUrl={redirectRoute} backButtonTitle='Retour aux documents'>
                <CreateUploadDocumentForm
                    defaultFormState={defaultFormState}
                    operationSheets={operationSheets}
                    disabledFieldsSelection={false}
                    formState={state}
                    formErrors={errors}
                    disabledSelectDossierCEE={disabledSelectDossierCEE}
                    message={message}
                    updateFormState={updateState}
                    dossier={restOfInitData.dossierCEE || dossierCEE}
                    alerts={alerts}
                    onChange={handleChange}
                    onSave={handleSave}
                    isSupportAccount={account?.email?.includes('support@renolib.fr')}
                    checkIfDocumentExist={checkIfDocumentExist(state)}
                    onResetDocumentCheckMessage={handleResetDocumentCheckMessage}
                />
            </Container>
        </>
    );
};
