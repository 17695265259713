import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Autocomplete, BlockCard, Button, Icons } from '@renolib/renolib-ui-kit';
import { useMutation } from '@tanstack/react-query';
import _ from 'lodash';

import formsActions from '../../../../store/actions/ui/form';
import customerPmService from '../../../../store/services/customer-pm.service';
import history from '../../../../utils/history';
import Form from '../../../helpers/Form';
import { ErrorWithBadges, WarningWithBadges } from '../../../helpers/MessageWithBadges';

export const SearchCustomersPMBySiren = ({ customerPMInseeInformation, onSearch, error, isSearchLoading, customerPmAutocompleteResult, onSelectCustomer, isAnErrorTriggeringManualMode }) => {
    const dispatch = useDispatch();
    const [searchedSiren, setSearchedSiren] = useState();
    const [canCreateError, setCanCreateError] = useState();

    const handleSuccess = (data = {}) => {
        history.push(`/creer-client-professionnel/2/${data?.siren}`);
    };

    const handleError = (error) => {
        const message = _.get(error, 'response.data.message');
        setCanCreateError(message || 'Une erreur est survenue');
    };
    const onCanCreateFullCustomerPM = useMutation({
        mutationFn: customerPmService.canCreateACustomerPM,
        onMutate() {
            dispatch(formsActions.startRequesting());
        },
        onSuccess: handleSuccess,
        onError: handleError,
        onSettled() {
            dispatch(formsActions.stopRequesting());
        },
    });

    const onSubmit = useCallback(() => {
        if (isAnErrorTriggeringManualMode) {
            handleSuccess({ siren: 'MANUAL' });
            //onCanCreateFullCustomerPM.mutate({ data: { details: {} } });
            return;
        }
        onCanCreateFullCustomerPM.mutate({ data: { details: customerPMInseeInformation } });
    }, [customerPMInseeInformation, isAnErrorTriggeringManualMode, onCanCreateFullCustomerPM]);

    const handleSelect = ({ option }) => {
        onSelectCustomer(option.value);
    };

    const onUpdateSearchValue = ({ value }) => {
        const formattedValue = value.replace(/ /g, '');
        if (!/^\d+$/.test(formattedValue)) {
            setSearchedSiren('');
            setCanCreateError('Le siren doit contenir uniquement des chiffres');
            return;
        }
        setSearchedSiren(formattedValue);
        onSearch({ value: formattedValue });
        setCanCreateError('');
    };

    const customerEtablissementsWarnings = useMemo(() => {
        if (!customerPMInseeInformation) return { errors: '' };
        let errors = '';
        const etablissements = _.get(customerPMInseeInformation, 'etablissements', []);
        const mainAddress = etablissements.find(({ etablissementSiege }) => etablissementSiege);
        if (!mainAddress) {
            errors = `Votre siege social est fermé. `;
        }
        if (etablissements.length === 0) {
            errors += errors ? `De plus vous n'avez aucun établissement d'ouvert` : "Vous n'avez aucun établissement d'ouvert";
        }
        return { errors };
    }, [customerPMInseeInformation]);

    return (
        <BlockCard title='Rechercher une entreprise' icon={Icons.blockOrganization}>
            <Form.Group>
                {(error || canCreateError) && <ErrorWithBadges message={error || canCreateError} />}
                <div className={'flex-column justify-content-center'}>
                    <Autocomplete
                        fluid
                        async
                        label={'MERCI DE RENSEIGNER LE NUMÉRO SIREN DE VOTRE NOUVEAU CLIENT'}
                        loading={isSearchLoading}
                        defaultValue={searchedSiren}
                        options={customerPmAutocompleteResult}
                        disabled={false}
                        onSelect={handleSelect}
                        onChange={onUpdateSearchValue}
                        placeholder={searchedSiren}
                    />
                </div>
            </Form.Group>
            {customerEtablissementsWarnings.errors && customerEtablissementsWarnings.errors && (
                <div className={'mb-3 mt-2 '} key={`error-etablissement-`}>
                    <WarningWithBadges message={customerEtablissementsWarnings.errors} />
                </div>
            )}
            <div className={'flex-row justify-content-center pt-2'}>
                <Button lightMode={'1'} disabled={!isAnErrorTriggeringManualMode && !customerPMInseeInformation} onClick={onSubmit} className='color-white'>
                    Suivant
                </Button>
            </div>
        </BlockCard>
    );
};
