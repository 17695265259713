import React from 'react';
import { Text } from '@renolib/renolib-ui-kit';

import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import fr from 'react-phone-number-input/locale/fr';

export default function PhoneInputWithCountries({ name, label, defaultValue, error, onChange }) {
    // `value` will be the parsed in format E.164, example: "+12133734253".
    function handleChange(value) {
        onChange({ name, value });
    }

    return (
        <>
            <Text.Label>{label}</Text.Label>
            <PhoneInput
                className='mt-2 mb-1'
                placeholder='Enter phone number'
                value={defaultValue}
                onChange={handleChange}
                defaultCountry='FR'
                labels={fr}
                international
                countryCallingCodeEditable={false}
                countries={['FR', 'GP', 'MQ', 'RE', 'YT', 'GF']}
                countryOptionsOrder={['FR']}
            />
            {error && <span className='color-error'>{error}</span>}
        </>
    );
}
