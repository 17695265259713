import { isValidValue } from '.';
import { COUNTRIES } from '../data/countries';

function formatFinderPlacesResults(places) {
    return places.map((place) => ({
        label: place.addressText,
        value: place.placeId,
    }));
}

function areAllAddressPartsValid(address) {
    const addressPartsProperties = ['streetName', 'city', 'postalCode', 'country'];

    return addressPartsProperties.every((propertyName) => isValidValue(address[propertyName]));
}

function getCountrySelectOptions() {
    return Object.values(COUNTRIES).map((country) => ({ label: country, value: country }));
}

function cleanStr(str) {
    return str.replace('№', 'N°');
}
export default { formatFinderPlacesResults, areAllAddressPartsValid, getCountrySelectOptions, cleanStr };
