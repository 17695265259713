import validateArrayTarget from './operation_form_helper_utils/validate-array-target';
import validateObjectTarget from './operation_form_helper_utils/validate-object-target';
import { energyRenovationServiceFormFieldsDescriptor } from './entity_fields_descriptors';
import {
    auxiliaryHeatingModesTranslations,
    buildingAges,
    heatingEquipmentFunctioningTypes,
    heatingEquipmentFunctioningTypesTranslations,
    operationDetailTypes,
    operationHeatingTypes,
    operationInstallationFinishesTranslations,
    operationInstallationTypes,
    operationInstallationTypesTranslations,
    operationSaleTypesTranslations,
    productTypes,
    removalOldBoilerTypes,
} from './enums';
import formValidation from './form_validation';
import {
    LABEL_HOUSE_CONTAINS_HEATED_FLOORS_OR_CEILINGS,
    LABEL_INSTALLATION_FINISHES,
    LABEL_OPERATION_TYPE,
    LABEL_REGULATION_SYSTEM_INSTALLED_ON_AIR_AIR_SYSTEM,
    LABEL_REGULATION_SYSTEM_IS_AT_MOST_OF_CLASS_C,
    LABEL_REGULATION_SYSTEM_IS_INSTALLED_IN_EVERY_ROOM_THAT_HAVE_A_HEAT_EMITTER,
    LABEL_REGULATION_SYSTEM_TEMPERATURE_IS_UPDATABLE_FOR_EACH_ROOM,
    LABEL_SELECT_AUXILIARY_HEATING_MODE,
} from './operation-constants';
import operationSheetNames from './operation-sheet-names';
import { arePropertiesIncludedInObject, cloneDeep, isNonEmptyObject, isNullishOrEmpty, isValidValue } from '.';

function validateOperationForm(formState, operationFormDescriptor, descriptorData = {}) {
    const { installationDetailsToSpecify = [], exclusionDetailsToSpecify = [], otherDetailsToSpecify = [], ...operationProperties } = formState;

    const errorMessages = [];
    const { propertiesDescriptor, installationDetailsToSpecifyPropertiesDescriptor, exclusionDetailsToSpecifyPropertiesDescriptor, otherDetailsToSpecifyPropertiesDescriptor } =
        operationFormDescriptor;

    const operationValidation = validateObjectTarget(operationProperties, propertiesDescriptor, descriptorData);
    const installationDetailsToSpecifyValidation = validateArrayTarget(installationDetailsToSpecify, installationDetailsToSpecifyPropertiesDescriptor, descriptorData);
    const exclusionDetailsToSpecifyValidation = validateArrayTarget(exclusionDetailsToSpecify, exclusionDetailsToSpecifyPropertiesDescriptor, descriptorData);
    const otherDetailsToSpecifyValidation = validateArrayTarget(otherDetailsToSpecify, otherDetailsToSpecifyPropertiesDescriptor, descriptorData);

    const newFormState = {
        ...operationValidation.newTarget,
        installationDetailsToSpecify: installationDetailsToSpecifyValidation.newTarget,
        exclusionDetailsToSpecify: exclusionDetailsToSpecifyValidation.newTarget,
        otherDetailsToSpecify: otherDetailsToSpecifyValidation.newTarget,
    };

    const validForm =
        operationValidation.validTarget && installationDetailsToSpecifyValidation.validTarget && exclusionDetailsToSpecifyValidation.validTarget && otherDetailsToSpecifyValidation.validTarget;
    if (!validForm) errorMessages.push('Veuillez compléter le formulaire');

    return { newFormState, validForm, errorMessages };
}

function extractPropertiesOfOperationArray(array = []) {
    const properties = {};
    array.forEach(({ label, value }) => {
        properties[label] = value;
    });

    return properties;
}

function isLabelIncludedInOperationArray(array = [], targetLabel, targetValue) {
    return !!array.find(({ label, value }) => label === targetLabel && value === targetValue);
}

function extractPropertyFromOperationArray(array = [], label) {
    return array.find((item) => item.label === label)?.value;
}

function getOtherDetailToSpecifyValue(operation, label) {
    const { otherDetailsToSpecify = [] } = operation;

    return extractPropertyFromOperationArray(otherDetailsToSpecify, label);
}

function getAuxiliaryHeatingModeTranslation(heatingMode) {
    return auxiliaryHeatingModesTranslations[heatingMode] || '';
}

function getInstallationDetailToSpecifyValue(operation, label) {
    const { installationDetailsToSpecify = [] } = operation;

    const strategies = {
        [LABEL_SELECT_AUXILIARY_HEATING_MODE]: getAuxiliaryHeatingModeTranslation,
    };

    return Object.keys(strategies).includes(label)
        ? strategies[label](extractPropertyFromOperationArray(installationDetailsToSpecify, label))
        : extractPropertyFromOperationArray(installationDetailsToSpecify, label);
}

function getOptionalDetailValue(operation, label) {
    const { optionalDetails = [] } = operation;

    return extractPropertyFromOperationArray(optionalDetails, label);
}

function getInstallationFinishesValue(operation, { onlyCustomValue = false, onlySelectedValue = false } = {}) {
    const value = getInstallationDetailToSpecifyValue(operation, LABEL_INSTALLATION_FINISHES.DEFAULT);
    if (!value) return '';
    if (onlyCustomValue) return isValueIncludedInInstallationFinishesValues(value) ? '' : value;
    if (onlySelectedValue) return isValueIncludedInInstallationFinishesValues(value) ? value : operationInstallationFinishesTranslations.AUTRE;

    return value;
}

function isValueIncludedInInstallationFinishesValues(value) {
    const installationFinishesValues = Object.values(operationInstallationFinishesTranslations);

    return installationFinishesValues.includes(value);
}

function getExclusionDetailToSpecifyValue(operation, label) {
    const { exclusionDetailsToSpecify = [] } = operation;

    return extractPropertyFromOperationArray(exclusionDetailsToSpecify, label);
}

function translateInstallationType(installationType) {
    return operationInstallationTypesTranslations[installationType] || '';
}

function translateSaleType(installationType) {
    return operationSaleTypesTranslations[installationType] || '';
}

function getHeatingTypeLabel(operation, label) {
    if (isNullishOrEmpty(operation) || isNullishOrEmpty(operation.otherDetailsToSpecify)) return '';

    const heatingType = getOtherDetailToSpecifyValue(operation, label);
    const labels = {
        [operationHeatingTypes.ELECTRICITE]: 'Électricité',
        [operationHeatingTypes.COMBUSTIBLE]: 'Combustible',
    };

    return labels[heatingType] || '';
}

function isInstallationTypeValid(installationType) {
    return !!installationType && installationType !== operationInstallationTypes.NONE;
}

function getCompliancy(operation) {
    return operation.compliant || false;
}

function canCheckOperationCompliancy(operation) {
    return operation?.blockValidity?.valid && !!getCompliancyMessage(operation) && !operation.compliant;
}

function getCompliancyMessage(operation) {
    const { compliancyMessages = [] } = operation;
    return compliancyMessages.length > 0 ? compliancyMessages[0] : '';
}

function canCheckTechnicianCompliancy(technician) {
    const { errorMessages = [] } = technician?.blockValidity;

    return errorMessages.length > 0;
}

function doesOperationRequireInstallationFinishes(operation) {
    return [
        operationSheetNames.BAR_EN_102V_A14_1,
        operationSheetNames.BAR_EN_102V_A39_2,
        operationSheetNames.BAR_EN_102V_A65_4,
        operationSheetNames.BAR_EN_107,
        operationSheetNames.BAT_EN_102,
        operationSheetNames.BAT_EN_108,
    ].includes(operation.operationSheetName);
}

function removeOperationVersion(operationSheetName) {
    if (!operationSheetName) return;
    return operationSheetName.replace(/_/g, '-').split('V-')[0];
}

function getOperationEn106WithEn109Included(operation, operations) {
    const operationPairs = [
        { main: [operationSheetNames.BAT_EN_106], related: operationSheetNames.BAT_EN_109 },
        { main: [operationSheetNames.BAR_EN_106V_A33_4, operationSheetNames.BAR_EN_106V_A64_5], related: operationSheetNames.BAR_EN_109 },
    ];

    const matchingPair = operationPairs.find((pair) => pair.main.includes(operation?.operationSheetName) && isNonEmptyObject(findOperation(operations, pair.related)));

    return matchingPair ? matchingPair.related : null;
}

function getOperationEn109WithEn106Included(operation, operations) {
    const operationPairs = [
        { main: operationSheetNames.BAT_EN_109, relateds: [operationSheetNames.BAT_EN_106] },
        { main: operationSheetNames.BAR_EN_109, relateds: [operationSheetNames.BAR_EN_106V_A33_4, operationSheetNames.BAR_EN_106V_A64_5] },
    ];

    const matchingPair = operationPairs.find((pair) => {
        if (operation?.operationSheetName === pair.main) {
            const relatedOperation = findOperationByOperationSheetNames(operations, pair.relateds);
            pair.related = relatedOperation?.operationSheetName;
        }
        return pair;
    });

    return matchingPair ? matchingPair.related : null;
}

function findOperation(operations, operationSheetName) {
    return operations.find((operation) => operation.operationSheetName === operationSheetName);
}

function findOperationByOperationSheetNames(operations, operationSheetNames) {
    return operations.find((operation) => operationSheetNames.includes(operation.operationSheetName));
}

function findBarOrBatEn106Operation(operations, operationName) {
    if (operationName === 'BAR_EN_106') return operations.find((operation) => [operationSheetNames.BAR_EN_106V_A33_4, operationSheetNames.BAR_EN_106V_A64_5].includes(operation.operationSheetName));
    if (operationName === 'BAT_EN_106') return operations.find((operation) => [operationSheetNames.BAT_EN_106].includes(operation.operationSheetName));
}

function isOperationInstallationStartDateInputDisabled(operation, operations) {
    return getOperationEn109WithEn106Included(operation, operations);
}

function isOperationInstallationEndDateInputDisabled(operation, operations) {
    return getOperationEn109WithEn106Included(operation, operations);
}

function getSelectPreviousHeatingEquipmentFunctioningTypeOptions() {
    return Object.values(heatingEquipmentFunctioningTypes).map((type) => ({
        label: heatingEquipmentFunctioningTypesTranslations[type],
        value: type,
    }));
}

function validateEnergyRenovationServiceForm(operation, { name, value }) {
    const nextOperation = cloneDeep(operation);
    nextOperation[name] = value;

    const { validForm, formErrors } = formValidation.validateForm(nextOperation, energyRenovationServiceFormFieldsDescriptor);
    nextOperation.blockValidity.valid = validForm;
    nextOperation.blockValidity.errorMessages = Object.values(formErrors);
    if (isValidValue(nextOperation.quantity) && isValidValue(nextOperation.price)) {
        nextOperation.totalPrice = nextOperation.quantity * nextOperation.price;
    }

    return nextOperation;
}

function updateNonEnergyRenovationOperationMainProduct(products = [], updates) {
    return products.map((operationProduct) => {
        if (operationProduct?.product?.type === productTypes.NON_ENERGY_RENOVATION_OPERATION_MAIN_PRODUCT) {
            const nextOperationProduct = { ...operationProduct, ...updates };
            if (arePropertiesIncludedInObject(updates, ['quantity', 'price'])) {
                nextOperationProduct.totalPrice = updates.quantity * updates.price;
            }

            return nextOperationProduct;
        }

        return operationProduct;
    });
}

function getSelectBarEn101InstallationTypesOptions() {
    return [
        { label: 'Comble perdu', value: operationInstallationTypes.COMBLE_PERDU },
        { label: 'Rampant de toiture', value: operationInstallationTypes.RAMPANT_DE_TOITURE },
    ];
}

function getRemovalOldBoilerApplicability(address, operation) {
    let removalOldBoilerOptionApplicable = true;
    const fuelTankRemovalApplicabilityMessages = [];
    if (!isBuildingOld(address)) {
        removalOldBoilerOptionApplicable = false;
        fuelTankRemovalApplicabilityMessages.push('Le bâtiment doit avoir au moins 2 ans');
    }
    if (!isPreviousHeatingEquipmentFunctioning(operation)) {
        removalOldBoilerOptionApplicable = false;
        fuelTankRemovalApplicabilityMessages.push("L'équipement à installer doit remplacer une chaudière individuelle fonctionnant au fioul");
    }
    if (!isMainProductSelected(operation)) {
        removalOldBoilerOptionApplicable = false;
        fuelTankRemovalApplicabilityMessages.push('Les informations du produit doivent être renseignées');
    }

    return { removalOldBoilerOptionApplicable, fuelTankRemovalApplicabilityMessages };
}

function isBuildingOld({ buildingAge }) {
    return [buildingAges.BUILDING_IS_OLDER_THAN_TWO_YEARS, buildingAges.BUILDING_IS_OLDER_THAN_FIFTEEN_YEARS].includes(buildingAge);
}

function isPreviousHeatingEquipmentFunctioning(operation) {
    return operation && operation?.installationDetailsToSpecify?.some(({ value }) => Object.values(heatingEquipmentFunctioningTypes).includes(value));
}

function isMainProductSelected({ products = [] }) {
    return isNonEmptyObject(products.find((operationProduct) => operationProduct?.product?.type === productTypes.MAIN_PRODUCT && operationProduct?.blockValidity?.valid));
}

function extractRemovalOldBoilerService({ products = [] } = {}) {
    return products.find(isRemovalOldBoilerService);
}

function isRemovalOldBoilerService(operationProduct) {
    const installationType = operationProduct?.product?.installationType;

    return (
        installationType && [removalOldBoilerTypes.REMOVAL_OLD_FUEL_BOILER, removalOldBoilerTypes.REMOVAL_OLD_GAS_BOILER, removalOldBoilerTypes.REMOVAL_OLD_CHARCOAL_BOILER].includes(installationType)
    );
}

function isRemovalOldBoilerServiceAlreadyPresentInOperation(operation) {
    return isNonEmptyObject(operation.products.find(isRemovalOldBoilerService));
}

function extractFuelTankRemovalService({ products = [] } = {}) {
    return products.find(isFuelTankRemovalService);
}

function isFuelTankRemovalService(operationProduct) {
    const installationType = operationProduct?.product?.installationType;

    return installationType && installationType === removalOldBoilerTypes.FUEL_TANK_REMOVAL;
}

function isFuelTankRemovalServiceAlreadyPresentInOperation(operation) {
    return isNonEmptyObject(operation.products.find(isFuelTankRemovalService));
}

function initializeOperationForm(operationSheetName) {
    const initializationStrategy = {
        [operationSheetNames.BAR_TH_173V_A56_1]: initializeOperationFormForBarTh173VA561,
        [operationSheetNames.BAR_TH_173V_A56_1_CDP]: initializeOperationFormForBarTh173VA561,
    }[operationSheetName];

    return initializationStrategy ? initializationStrategy() : {};
}

function initializeOperationFormForBarTh173VA561() {
    return {
        technicalVisit: false,
        installationDetailsToSpecify: [
            { label: LABEL_OPERATION_TYPE, value: operationDetailTypes.EXISTING_REGULATION_SYSTEM_REPLACEMENT },
            { label: LABEL_REGULATION_SYSTEM_IS_AT_MOST_OF_CLASS_C, value: true },
            { label: LABEL_REGULATION_SYSTEM_IS_INSTALLED_IN_EVERY_ROOM_THAT_HAVE_A_HEAT_EMITTER, value: true },
            { label: LABEL_REGULATION_SYSTEM_TEMPERATURE_IS_UPDATABLE_FOR_EACH_ROOM, value: true },
            { label: LABEL_REGULATION_SYSTEM_INSTALLED_ON_AIR_AIR_SYSTEM, value: true },
            { label: LABEL_HOUSE_CONTAINS_HEATED_FLOORS_OR_CEILINGS, value: false },
        ],
    };
}

function getSelectAuxiliaryHeatingModeOptions() {
    return Object.keys(auxiliaryHeatingModesTranslations).map((auxiliaryHeatingMode) => {
        return { label: auxiliaryHeatingModesTranslations[auxiliaryHeatingMode], value: auxiliaryHeatingMode };
    });
}

export default {
    validateOperationForm,
    extractPropertiesOfOperationArray,
    isLabelIncludedInOperationArray,
    getOtherDetailToSpecifyValue,
    getInstallationDetailToSpecifyValue,
    getExclusionDetailToSpecifyValue,
    translateInstallationType,
    getHeatingTypeLabel,
    isInstallationTypeValid,
    getCompliancy,
    canCheckOperationCompliancy,
    getCompliancyMessage,
    canCheckTechnicianCompliancy,
    getInstallationFinishesValue,
    doesOperationRequireInstallationFinishes,
    removeOperationVersion,
    findOperation,
    isOperationInstallationStartDateInputDisabled,
    isOperationInstallationEndDateInputDisabled,
    getSelectPreviousHeatingEquipmentFunctioningTypeOptions,
    validateEnergyRenovationServiceForm,
    translateSaleType,
    updateNonEnergyRenovationOperationMainProduct,
    getSelectBarEn101InstallationTypesOptions,
    isBuildingOld,
    getRemovalOldBoilerApplicability,
    extractRemovalOldBoilerService,
    isRemovalOldBoilerService,
    isRemovalOldBoilerServiceAlreadyPresentInOperation,
    initializeOperationForm,
    getOperationEn106WithEn109Included,
    getOperationEn109WithEn106Included,
    getSelectAuxiliaryHeatingModeOptions,
    getOptionalDetailValue,
    findBarOrBatEn106Operation,
    isFuelTankRemovalServiceAlreadyPresentInOperation,
    extractFuelTankRemovalService,
    isFuelTankRemovalService,
};
