import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import _ from 'lodash';

import formsActions from '../../../../store/actions/ui/form';
import customerPmService from '../../../../store/services/customer-pm.service';
import { isNonEmptyObject } from '../../../../utils';
import { renovationAddressBuildingSectors } from '../../../../utils/enums';
import history from '../../../../utils/history';
import { useGetCustomerPMThroughInsee } from '../../../hooks/useGetCustomerPMThroughInsee';
import { WithCenteredContainerWrapper } from '../../WithReturnContainerWrapper';

import { adaptRenovationAddressFromInseeToApiNecessite, formatCustomerForApi, fromCustomerInseeToCustomerFrontForm } from './create-customers-pm.helper';
import { SearchCustomersPMBySiren } from './SearchCustomersPMBySiren';
import { SetCustomersAddresses } from './SetCustomersAddresses';
import { SetCustomersContact } from './SetCustomersContact';
import { SetCustomersPMInformation } from './SetCustomersPmInformation';
import { WithApiController } from './WithApiController';
import { WithRedirect } from './WithRedirect';

export const CreateCustomersPM = () => {
    const { searchError, customerPmAutocompleteResult, onDebounceSearchCustomerPm, isSearchLoading, setSelectedCustomer, setInseeRequestOptions, selectedCustomer, isAnErrorTriggeringManualMode } =
        useGetCustomerPMThroughInsee({
            options: { withSocietyInfoExtension: true, apiMode: 'NORMAL' },
        });

    const dispatch = useDispatch();
    const [createCustomerError, setCreateCustomerErrors] = useState();

    const handleSuccess = ({ data }) => {
        history.push(`/clients/${data.uuid}?type=morale`);
    };

    const handleError = (error) => {
        setCreateCustomerErrors(error?.data?.message || error.response?.data?.message);
    };

    const onCreateFullCustomerPM = useMutation({
        mutationFn: customerPmService.createAFullCustomerPM,
        onMutate() {
            dispatch(formsActions.startRequesting());
        },
        onSuccess: handleSuccess,
        onError: handleError,
        onSettled() {
            dispatch(formsActions.stopRequesting());
        },
    });

    const [createCustomerPmForm, setCreateCustomerPmForm] = useState();

    const onSubmitCustomerPmInformation = useCallback(
        (data) => {
            setCreateCustomerPmForm((createCustomerPmForm) => ({ ...createCustomerPmForm, details: data }));
            history.push(`/creer-client-professionnel/3`);
        },
        [setCreateCustomerPmForm]
    );

    const onSubmitCustomerAddresses = useCallback(
        (data) => {
            setCreateCustomerPmForm((createCustomerPmForm) => ({ ...createCustomerPmForm, addresses: data }));
            history.push(`/creer-client-professionnel/4`);
        },
        [setCreateCustomerPmForm]
    );

    const onSubmitContact = useCallback(
        (contactData) => {
            const fullCustomers = { ...createCustomerPmForm, contacts: [contactData] };

            setCreateCustomerPmForm((createCustomerPmForm) => ({ ...createCustomerPmForm, contacts: [contactData] }));
            onCreateFullCustomerPM.mutate({ data: formatCustomerForApi(fullCustomers, { isManualMode: isAnErrorTriggeringManualMode }) });
        },
        [setCreateCustomerPmForm, createCustomerPmForm, isAnErrorTriggeringManualMode, onCreateFullCustomerPM]
    );

    const onSelectCustomerPM = (customer) => {
        setCreateCustomerPmForm(fromCustomerInseeToCustomerFrontForm(customer));
        setSelectedCustomer(customer);
    };

    return (
        <Switch>
            <Route path={'/creer-client-professionnel/1'} exact={true}>
                <WithCenteredContainerWrapper backUrl={'/clients'} backButtonTitle={'Retourner à la liste des clients'}>
                    <WithApiController onUpdateApiMode={(value) => setInseeRequestOptions((requestOption) => ({ ...requestOption, apiMode: value }))}>
                        <SearchCustomersPMBySiren
                            error={searchError}
                            customerPMInseeInformation={selectedCustomer}
                            onSelectCustomer={onSelectCustomerPM}
                            onSearch={onDebounceSearchCustomerPm}
                            customerPmAutocompleteResult={customerPmAutocompleteResult}
                            isSearchLoading={isSearchLoading}
                            isAnErrorTriggeringManualMode={isAnErrorTriggeringManualMode}
                        />
                    </WithApiController>
                </WithCenteredContainerWrapper>
            </Route>
            <Route path={'/creer-client-professionnel/2/:siren'} exact={true}>
                <SetCustomersPMInformation
                    customerPM={_.get(createCustomerPmForm, 'details')}
                    getCustomerPm={onDebounceSearchCustomerPm}
                    onSubmit={onSubmitCustomerPmInformation}
                    isManualMode={isAnErrorTriggeringManualMode}
                />
            </Route>
            <Route path={'/creer-client-professionnel/3'} exact={true}>
                <WithRedirect condition={() => !isNonEmptyObject(createCustomerPmForm?.details)} to={'/creer-client-professionnel/1'}>
                    <SetCustomersAddresses
                        customerPM={createCustomerPmForm}
                        getCustomerPm={onDebounceSearchCustomerPm}
                        isLoading={isSearchLoading}
                        onSubmit={onSubmitCustomerAddresses}
                        options={{
                            addressesOrigin: 'renovationAddresses',
                            availableBuildingSectors: [renovationAddressBuildingSectors.RESIDENTIAL, renovationAddressBuildingSectors.TERTIARY],
                            textOverloads: {},
                            withSiegeSocialConfig: true,
                            isSiegeSocialObligatory: true,
                            onAddFormatFunction: adaptRenovationAddressFromInseeToApiNecessite,
                        }}
                        withSiegeSocialConfig={true}
                        isManualMode={isAnErrorTriggeringManualMode}
                    />
                </WithRedirect>
            </Route>
            <Route path={'/creer-client-professionnel/4'} exact={true}>
                <WithRedirect condition={() => !isNonEmptyObject(createCustomerPmForm?.details)} to={'/creer-client-professionnel/1'}>
                    <SetCustomersContact getCustomerPm={onDebounceSearchCustomerPm} onSubmit={onSubmitContact} createCustomerError={createCustomerError} />
                </WithRedirect>
            </Route>
        </Switch>
    );
};
