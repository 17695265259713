import React, { useEffect } from 'react';
import history from '../../../../utils/history';
export const WithRedirect = ({ condition, to, children }) => {
    useEffect(() => {
        if (condition && condition()) {
            history.push(to);
        }
    }, [condition, to]);

    return <>{children}</>;
};
