import { isPossiblePhoneNumber } from 'react-phone-number-input';

import { isValidValue } from '.';
import { MUST_PROVIDE_AT_LEAT_ONE_PHONE_NUMBER, INVALID_PHONE_NUMBER } from './messages';

function validatePhoneForm(formState, formErrors) {
    if (!isValidValue(formState.phone) && !isValidValue(formState.mobile)) {
        formErrors.mobile = MUST_PROVIDE_AT_LEAT_ONE_PHONE_NUMBER;
        formErrors.phone = MUST_PROVIDE_AT_LEAT_ONE_PHONE_NUMBER;

        return false;
    }

    if (isValidValue(formState.phone)) {
        if (!isPossiblePhoneNumber(formState.phone)) {
            formErrors.phone = INVALID_PHONE_NUMBER;
            return false;
        }
    }

    if (isValidValue(formState.mobile)) {
        if (!isPossiblePhoneNumber(formState.mobile)) {
            formErrors.mobile = INVALID_PHONE_NUMBER;
            return false;
        }
    }

    return true;
}

export default { validatePhoneForm };
