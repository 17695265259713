import { authHeaders, endpointV2 } from '../../api';
import storage from '../../utils/storage';

const getBaseUrl = () => {
    return `/organizations/${storage.getOrganizationUUID()}/subscription`;
};

export const createCheckoutSingleFolders = (params) => endpointV2.post(getBaseUrl() + '/session-single-folders', params, { headers: authHeaders() });

export function manageSubscription(params) {
    return endpointV2.post(getBaseUrl() + '/manage-subscription', params, { headers: authHeaders() });
}
