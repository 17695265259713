import { customerPMActivitiesTranslations } from '../enums';

const customerPMActivityOptions = Object.keys(customerPMActivitiesTranslations)
    .filter((key) => key !== 'OTHER')
    .map((key) => ({
        value: key,
        label: customerPMActivitiesTranslations[key],
    }));

export { customerPMActivityOptions };
