import { certificateOfCompliancyFieldsDescriptor, postRenovationReportFieldsDescriptor } from './entity_fields_descriptors';
import { uploadDocumentTypes } from './enums';
import formValidation from './form_validation';
import uploadDocumentHelper from './upload-document-helper';
import { isValidValue } from '.';

function assignDefaultValues(state) {
    if (uploadDocumentHelper.isSignatureDateRequired(state.documentType) && !state.signatureDate) {
        state.signatureDate = new Date();
    }
}

function validateUploadDocumentForm(state) {
    let validForm = true;
    const formErrors = {};
    const formData = new FormData();

    assignDefaultValues(state);
    const {
        documentType,
        fileName,
        fileType,
        file,
        files,
        dossierCEEId,
        quotationId,
        signatureDate,
        comment,
        heatingPumpCoverageRate,
        operations,
        occupant,
        operationUUID,
        energeticClass,
        dpeOrAuditType,
        fileQuotationNumber,
        fileQuotationPages,
        grantLetterAmount,
        grantLetterMessage,
        grantLetterDate,
        uploadCode,
        grantLetterDossierReference,
        aiError,
        certificateOfCompliancyDetails = {
            professionalSignatureDate: state?.professionalSignatureDate,
            beneficiarySignatureDate: state?.beneficiarySignatureDate,
        },
        ...postRenovationReportDetails
    } = state;

    let audit = undefined;

    if (!documentType) {
        formErrors.documentType = 'Type de document obligatoire';
        validForm = false;
    }

    const isSitePhoto = [uploadDocumentTypes.SITE_PHOTO_AFTER_RENOVATION, uploadDocumentTypes.SITE_PHOTO_BEFORE_RENOVATION].includes(documentType);
    const isAudit = [uploadDocumentTypes.AUDIT_AFTER_RENOVATION, uploadDocumentTypes.AUDIT_BEFORE_RENOVATION].includes(documentType);
    const isAttestation = [uploadDocumentTypes.ATTESTATION_CHANGE_OF_SUBCONTRACTOR, uploadDocumentTypes.ATTESTATION_CHANGE_OF_PRODUCT].includes(documentType);

    if (documentType === uploadDocumentTypes.TAX_NOTICE && !occupant) {
        formErrors.occupant = 'Sélectionnez un occupant';
        validForm = false;
    }
    if (!file) {
        formErrors.file = 'Sélectionnez un fichier';
        validForm = false;
    }
    if (documentType === uploadDocumentTypes.DPE_OR_ENERGETIC_AUDIT && !dpeOrAuditType) {
        formErrors.dpeOrAuditType = 'Sélectionnez un type de document';
        validForm = false;
    }
    if (!dossierCEEId && !quotationId) {
        formErrors.dossierCEEId = 'Compte rattaché obligatoire';
        validForm = false;
    }
    if (isSitePhoto) {
        if (!operations || operations.length === 0) {
            formErrors.operations = 'Vous devez sélectionner au moins une opération';
            validForm = false;
        }
    }
    if (isAudit) {
        if (!energeticClass) {
            formErrors.energeticClass = 'Vous devez sélectionner la classe énergétique';
            validForm = false;
        }
        if (!signatureDate) {
            formErrors.signatureDate = 'Vous devez sélectionner la date de signature';
            validForm = false;
        }

        audit = { energeticClass, signatureDate };
    }

    if (uploadDocumentHelper.canShowHeatingPumpCoverageRateInput(documentType, state)) {
        if (!heatingPumpCoverageRate) {
            formErrors.heatingPumpCoverageRate = 'Taux de couverture de la pompe à chaleur obligatoire';
            validForm = false;
        }
        const savedHeatingPumpCoverageRate = uploadDocumentHelper.extractHeatingPumpCoverageRate(state);
        if (heatingPumpCoverageRate < savedHeatingPumpCoverageRate) {
            formErrors.heatingPumpCoverageRate = `Le taux de couverture doit être ≥ à ${savedHeatingPumpCoverageRate}% (taux renseigné sur votre devis client)`;
            validForm = false;
        }
    }
    if (uploadDocumentHelper.isPostRenovationReport(documentType)) {
        if (!postRenovationReportDetails.accreditationExpirationDate) postRenovationReportDetails.accreditationExpirationDate = new Date();

        const postRenovationReportValidation = formValidation.validateForm(postRenovationReportDetails, postRenovationReportFieldsDescriptor);
        validForm = validForm && postRenovationReportValidation.validForm;
        Object.assign(formErrors, postRenovationReportValidation.formErrors);
    }
    if (uploadDocumentHelper.isCertificateOfCompliancy(documentType)) {
        const certificateOfCompliancyValidation = formValidation.validateForm(certificateOfCompliancyDetails, certificateOfCompliancyFieldsDescriptor);
        validForm = validForm && certificateOfCompliancyValidation.validForm;
        Object.assign(formErrors, certificateOfCompliancyValidation.formErrors);
    }
    if (!isSitePhoto && !fileName) {
        formErrors.fileName = 'Nom du document obligatoire';
        validForm = false;
    }

    const uploadDocumentData = {
        documentType,
        fileType,
        signatureDate,
        postRenovationReportDetails,
        certificateOfCompliancyDetails,
        sizingNoteDetails: { heatingPumpCoverageRate },
        dpeOrAuditDetails: { dpeOrAuditType },
        audit,
    };
    if (operationUUID) uploadDocumentData.operationUUID = operationUUID;
    if (!isSitePhoto) uploadDocumentData.fileName = fileName;

    if (documentType === uploadDocumentTypes.QUOTATION_SIGNED) {
        uploadDocumentData.uploadCode = uploadCode
    }
    if (isValidValue(comment)) uploadDocumentData.comment = comment;
    if (!uploadDocumentHelper.isPostRenovationReport(uploadDocumentData.documentType)) {
        delete uploadDocumentData.postRenovationReportDetails;
    }
    if (!uploadDocumentHelper.isCertificateOfCompliancy(uploadDocumentData.documentType)) delete uploadDocumentData.certificateOfCompliancyDetails;

    if (isSitePhoto) uploadDocumentData.operations = operations;
    else if (uploadDocumentData.operations) {
        delete uploadDocumentData.operations;
    }

    if (fileQuotationNumber && fileQuotationPages) {
        uploadDocumentData.fileQuotationNumber = fileQuotationNumber;
        uploadDocumentData.fileQuotationPages = fileQuotationPages;
    }

    formData.append('quotationId', quotationId);
    formData.append('dossierCEEId', dossierCEEId);
    formData.append('uploadDocumentData', JSON.stringify(uploadDocumentData));
    if (operationUUID) {
        formData.append('operationUUID', operationUUID);
    }
    if (isAttestation && !operationUUID) {
        formErrors.operationUUID = 'Veuillez sélectionner une opération';
        validForm = false;
    }

    if (isSitePhoto) {
        files.forEach((item) => formData.append('files', item.file));
    } else {
        formData.append('files', file);
    }

    if (grantLetterAmount) formData.append('grantLetterAmount', grantLetterAmount);
    if (grantLetterMessage) formData.append('grantLetterMessage', grantLetterMessage);
    if (grantLetterDate) formData.append('grantLetterDate', grantLetterDate);
    if (grantLetterDossierReference) formData.append('grantLetterDossierReference', grantLetterDossierReference);
    if (aiError) formData.append('aiError', aiError);

    return { validForm, formErrors, formData };
}

export default { validateUploadDocumentForm };
