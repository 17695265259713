import styled from 'styled-components';

import colors from '../../config/colors';
import dimensions from '../../config/dimensions';

export default styled.div`
    padding: 1rem;
    background: ${colors.primaryBackground};
    border-radius: ${dimensions.radius};
    margin-bottom: 0.75rem;
`;
