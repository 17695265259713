import styled from 'styled-components';

import { isInPhoneMode } from '../layout/layout.helper';

const ViewerFormContainer = styled.div`
    display: flex;
    justify-content: space-between;

    > div {
        :nth-child(1) {
            flex: 1;
            margin-right: 2rem;
            width: ${isInPhoneMode() ? '100%' : '60%'};
        }

        :nth-child(2) {
            width: ${isInPhoneMode() ? '0%' : '40%'};
        }
    }

    > form {
        width: ${isInPhoneMode() ? '100%' : '40%'};
    }
`;

export default ViewerFormContainer;
