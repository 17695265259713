import { genders } from '../enums';

const genderOptions = Object.keys(genders).map((key) => ({
    value: key,
    label: genders[key],
}));

const yesOrNoOptions = [
    { label: 'Oui', value: true },
    { label: 'Non', value: false },
];

export { genderOptions, yesOrNoOptions };
