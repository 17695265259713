import React from 'react';
import PropTypes from 'prop-types';
import { Field, FileUpload } from '@renolib/renolib-ui-kit';

import { genderOptions } from '../../../utils/select_options/shared';
import { administrativeContactRoleOptions } from '../../../utils/select_options/administrative-contact';
import { administrativeContactPropType } from '../../../utils/prop-types-schemas';

import Form from '../../helpers/Form';
import { LABEL_INPUT_FIRST_NAME, LABEL_INPUT_MOBILE, LABEL_INPUT_PHONE } from '../../../utils/form_labels';
import useUploadDocument from '../../hooks/useUploadDocument';
import PhoneInputWithCountries from '../../phoneInput/PhoneInputWithCountries';

function ContactForm({ formState = {}, formErrors = {}, onChange, isOrganization = false, displayOnlyFileUpload = false }) {
    const { onFileSizeExceed } = useUploadDocument();

    function handleChange({ file }) {
        onChange({ name: 'signatureFile', value: file });
    }

    function handleChangeFunction({ value }) {
        onChange({ name: 'role', value });
    }
    return (
        <>
            <Form.Group>
                <Field type='radio-group' label='CIVILITÉ' name='gender' options={genderOptions} onChange={onChange} fluid error={formErrors.gender} defaultValue={formState.gender} />
            </Form.Group>
            <Form.Group>
                <Field fluid type='text' label='NOM' name='lastName' onChange={onChange} error={formErrors.lastName} defaultValue={formState.lastName} />
            </Form.Group>
            <Form.Group>
                <Field fluid type='text' label={LABEL_INPUT_FIRST_NAME} name='firstName' onChange={onChange} error={formErrors.firstName} defaultValue={formState.firstName} />
            </Form.Group>
            <Form.Group>
                <Field
                    fluid
                    type='select'
                    label='Choisir une fonction'
                    name='role'
                    options={administrativeContactRoleOptions}
                    onChange={handleChangeFunction}
                    error={undefined}
                    placeholder={undefined}
                />
            </Form.Group>
            <Form.Group>
                <Field fluid type='text' label='FONCTION' name='role' options={administrativeContactRoleOptions} onChange={onChange} error={formErrors.role} defaultValue={formState?.role} />
            </Form.Group>
            <Form.Group>
                <Field fluid type='email' label='ADRESSE EMAIL' name='email' onChange={onChange} error={formErrors.email} defaultValue={formState.email} />
            </Form.Group>
            <Form.Group>
                <PhoneInputWithCountries name='mobile' label={LABEL_INPUT_MOBILE} defaultValue={formState.mobile} error={formErrors.mobile} onChange={onChange} />
            </Form.Group>
            {
                //to do kit
            }
            <Form.Group>
                <PhoneInputWithCountries name='phone' label={LABEL_INPUT_PHONE} defaultValue={formState.phone} error={formErrors.phone} onChange={onChange} />
            </Form.Group>
            {!displayOnlyFileUpload && (
                <Form.Group>
                    <Field fluid type='checkbox' label='Contact signataire ?' name='isMainContact' onChange={onChange} error={formErrors.isMainContact} defaultChecked={formState.isMainContact} />
                </Form.Group>
            )}
            {formState.isMainContact && isOrganization && (
                <Form.Group>
                    <FileUpload
                        label="Signature du signataire et cachet de l'entreprise (un seul fichier image) (taille 10Mo maximum)"
                        onChange={handleChange}
                        onFileSizeExceed={onFileSizeExceed}
                        accept={['image/png', 'image/jpeg', 'image/jpg']}
                    />
                </Form.Group>
            )}
        </>
    );
}
ContactForm.propTypes = {
    formState: administrativeContactPropType,
    formErrors: PropTypes.shape({}),
    onChange: PropTypes.func.isRequired,
    isOrganization: PropTypes.bool,
    displayOnlyFileUpload: PropTypes.bool,
};

export default ContactForm;
