import { LABEL_CLASSE_ISOLANT } from '../../product-constants';

export default [
    {
        property: LABEL_CLASSE_ISOLANT,
        label: LABEL_CLASSE_ISOLANT,
        type: 'number',
        required: true,
    },
];
