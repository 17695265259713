import React from 'react';

export const ErrorWithBadges = ({ message, fontSize }) => {
    return (
        <div className={'background-color-light-error-opacity color-primary justify-content-center flex-row justify-content-center p-2 mb-4 mt-2 border-radius-10'}>
            <span className={`${fontSize || 'fs-10'} text-color-primary italic bold`}>{message}</span>
        </div>
    );
};

export const ValidWithBadges = ({ message }) => {
    return (
        <div className={'background-color-light-error-opacity color-primary justify-content-center flex-row justify-content-center p-2 mb-4 mt-2 border-radius-10'}>
            <span className={'fs-10 text-color-primary italic bold'}>{message}</span>
        </div>
    );
};

export const WarningWithBadges = ({ message }) => {
    return (
        <div className={'background-color-light-yellow-opacity color-primary justify-content-center flex-row justify-content-center p-2 border-radius-10'}>
            <span className={'fs-10 text-color-primary italic bold'}>{message}</span>
        </div>
    );
};
